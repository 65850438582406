import axios from 'axios';
import { produce } from 'immer';
import { saveAs } from 'file-saver';
import { SelectModalView } from '../../../../modules/BpOption/SelectModal/F/Function/selectmodal.js';
import {
  $attr,
  $closest,
  $find,
  $findAll,
  $addClass,
  $setAttr,
  $parent,
  $setHTML,
  $setVal,
  $val,
} from 'fxdom/es';
import {
  each,
  entries,
  find,
  go,
  head,
  some,
  join,
  last,
  omit,
  pluck,
  sel,
  tap,
  map,
  filter,
  identity,
  ifElse,
  noop,
  object,
  flatten,
  unzip,
  reject,
} from 'fxjs/es';
import { openCompositeTemplatesColorFrame } from '../../../../modules/Composite/ColorPicker/F/frame.js';
import { makeRedLineMockup } from '../../../../modules/Composite/Core/F/mockup_fns.js';
import { openCompositeTemplatesListFrame } from '../../../../modules/Composite/List/F/frame.js';
import { openCompositeTemplateTestFrame } from '../../../../modules/Composite/TestPage/F/frame.js';
import { DfOMPCustomBadgeRelationBPMuiF } from '../../../../modules/Df/OMP/CustomBadge/RelationBP/F/Mui/module/DfOMPCustomBadgeRelationBPMuiF.js';
import { makeImage } from '../../../../modules/Maker/F/canvas_trim.js';
import { openAutoCvPrintAreaTaskFrame } from '../../../../modules/Maker/F/cv_print_area_task/frame.js';
import { openCvPrintAreaEditor } from '../../../../modules/Maker/F/CvPreview/Editor/fs.js';
import { updatePcForOnlyDesignByBpId } from '../../../../modules/Maker/F/Migrate/product_color.js';
import { makeOgImageUrlFromUrl } from '../../../../modules/Maker/F/util.js';
import { MuiF } from '../../../../modules/Mui/F/Function/module/MuiF.js';
import { NewMakerBpsTemplateMetaMuiF } from '../../../../modules/NewMaker/BpsTemplateMeta/F/Mui/module/NewMakerBpsTemplateMetaMuiF.js';
import { downloadTextPrintImgOnly1Face1Size } from '../../../../modules/NewMaker/PrintResult/F/Function/makePxToResultPx.js';
import { BP_OPTION_LAYERS } from '../../../../modules/NewMaker/Property/Bpf/S/Constant/bpf.js';
import { NewMakerSizeFacesEditorMuiF } from '../../../../modules/NewMaker/SizeFacesEditor/F/Mui/module/NewMakerSizeFacesEditorMuiF.js';
import { legacyHtml } from '../../../../modules/Util/S/Function/util.js';
import { deleteBpLabelHandler, uploadBpLabelHandler } from './uploadBpLabelHandler.js';
import { UtilAlertF } from '../../../../modules/Util/Alert/F/Function/module/UtilAlertF.js';
import { guardSkuMeasurement, openSkuList } from './base_product_editor_skus.js';
import { UtilStringS } from '../../../../modules/Util/String/S/Function/module/UtilStringS.js';
import { ShippingS } from '../../../../modules/Shipping/S/Function/module/ShippingS.js';
import { DfInhouseF } from '../../../../modules/Df/Inhouse/F/Function/module/DfInhouseF.js';
import { DfBpLabelF } from '../../../../modules/Df/BpLabel/F/Function/module/DfBpLabelF.js';
import { UtilArrayS } from '../../../../modules/Util/Array/S/Function/module/UtilArrayS.js';
import { DfWaybillF } from '../../../../modules/Df/Waybill/F/Function/module/DfWaybillF.js';

const sessionStorageProxy = new Proxy(sessionStorage, {
  get(target, key) {
    return JSON.parse(target.getItem(key));
  },
  set(target, key, value) {
    if (key === 'removeItem' || value === null || value === undefined) return false;
    target.setItem(key, JSON.stringify(value));
    return true;
  },
});

async function isPassSamePrice(price_key_values, bp) {
  const makePriceSet = (price_key_values, target, lang, _collabo_type) => {
    const service = _collabo_type === '' ? '마플' : '마플샵';
    const _lang = lang === 'kr' ? '국문' : lang === 'en' ? '영문' : '일문';
    const _en = lang === 'kr' ? '' : lang === 'en' ? '_en' : '_jp';
    return go(
      price_key_values,
      map((price_key_value) => {
        return {
          price: target[price_key_value.v + _collabo_type + _en],
          key: `[${service}] ${_lang} ${price_key_value.key}`,
        };
      }),
    );
  };
  const not_same_prices = go(
    [
      flatten(['kr', 'en', 'jp'].map((lang) => makePriceSet(price_key_values, bp, lang, ''))),
      flatten(['kr', 'en', 'jp'].map((lang) => makePriceSet(price_key_values, bp, lang, '_creator'))),
    ],
    unzip,
    reject(([mp, creator]) => mp.price === creator.price),
  );
  if (not_same_prices?.length) {
    const is_continue = await $.confirm(
      `마플 마플샵 가격이 다릅니다.<br>그래도 계속 진행 하시겠습니까?<br><br>
                      ${go(
                        not_same_prices,
                        map((not_same_price) =>
                          go(
                            not_same_price,
                            map(({ key, price }) => `${key} : ${price}`),
                            join('<br>'),
                          ),
                        ),
                        join('<br><br>'),
                      )}
                      `,
    );
    if (!is_continue) return false;
  }
  return true;
}

const initStorage = () => {
  sessionStorageProxy.bp = {};
};
const removeStorageBp = () => {
  sessionStorage.removeItem('bp');
};

const updateArray = (data, parent_id, name, value) => {
  return produce(data, (draft) => {
    const index = draft.findIndex(({ id }) => id === parent_id);
    if (index === -1) draft.push({ id: parent_id, [name]: value });
    else if (index >= 0 && !value) draft.splice(index, 1);
    else if (index >= 0) draft[index][name] = value;
  });
};

const updateObject = (data, name, value) => {
  return produce(data, (draft) => {
    draft[name] = value;
  });
};

const setSession = (form_name, name, value, parent_id) => {
  if (!sessionStorageProxy.bp) {
    initStorage();
  }
  const bp = sessionStorageProxy.bp;
  if (parent_id) {
    if (!bp[form_name]) bp[form_name] = [];
    bp[form_name] = updateArray(bp[form_name], parent_id, name, value);
    if (bp[form_name].length === 0) delete bp[form_name];
    sessionStorageProxy.bp = bp;
    return;
  }
  if (!bp[form_name]) bp[form_name] = {};
  bp[form_name] = updateObject(bp[form_name], name, value);
  if (Object.keys(bp[form_name]).length === 0) delete bp[form_name];
  sessionStorageProxy.bp = bp;
};

const editFieldToSession = (el, editor) => {
  let name = el.name || el.classList?.[0] || el.id;
  if (el.tagName === 'TEXTAREA') {
    name = el.classList.length
      ? el.classList[0]
      : go(
          [el],
          map($closest('.editor_area')),
          filter(identity),
          map($find('[id]')),
          map($attr('id')),
          head,
        ) || go([el], map($closest('.editor')), map($attr('id')), head);
  }

  const form = go(el, $closest('[edit_mode]'));
  if (!form) return;

  const [form_name] = form?.classList;
  if (['seo_metas'].includes(form_name)) {
    return;
  }

  const parent_id = go(el, $closest('.parent'), box.sel)?.id;
  const value =
    el.type === 'checkbox'
      ? el.checked
      : el.tagName === 'TEXTAREA' && editor[name]
      ? editor[name].getHtml()
      : $val(el);

  setSession(form_name, name, value, parent_id);
};

const setValue = (name, value, editor) => (el) => {
  if (el.tagName === 'TEXTAREA') {
    editor[name] ? editor[name].setHtml(value) : $setHTML(value, el);
    return;
  }
  if (el.type === 'checkbox') {
    el.checked = value;
    return;
  }
  $setVal(value, el);
};

const setFields =
  (f_nm, editor, table) =>
  ([name, value]) =>
    go(
      f_nm,
      $find(
        `input.${name}, input[name="${name}"], select[name="${name}"], #${name} textarea, textarea.${name}`,
      ),
      ifElse(identity, setValue(name, value, editor), () => {
        if (table[name]) {
          table[name].data.tableResult = value;
          table[name].updateResult();
        } else {
          go(f_nm, $find(`#${name}`), ifElse(identity, setValue(name, value, editor), noop));
        }
      }),
    );

const setFieldsFromStorage = (don_tab, editor, table_map) => {
  go(
    sessionStorageProxy.bp,
    entries,
    each(([k, v]) => {
      const f_nm = go(don_tab, $find(`.${k}[edit_mode]`));
      if (Array.isArray(v)) {
        go(
          v,
          each((e) => {
            const id = e.id;
            const parent = go(f_nm, $find(`[_sel$="${id})"]`));
            go(omit(['id'], e), entries, each(setFields(parent, editor)));
          }),
        );
        return;
      }
      go(v, entries, each(setFields(f_nm, editor, table_map)));
    }),
  );
};

export default function (lo) {
  function bp_textarea_length(textarea, closest_sel) {
    const val = $.val(textarea);
    const byte_txt_el = $.find1($.closest(textarea, closest_sel), 'p .byte_txt');
    const abc = (val.length * 9 - encodeURIComponent(val).length) / 8; // 영문숫자의 개수
    const msg_byte = parseInt((val.length - abc) * 2 + abc, 10); // euc-kr 바이트 구하기

    $.html(byte_txt_el, msg_byte);
  }

  $.frame(
    {
      frame_name: 'df.base_product_editor',
      page_name: 'df.base_product_editor_page',
    },
    {
      page_name: 'df.base_product_editor_page',
    },
  );

  $.frame.defn_frame({
    frame_name: 'df.base_product_editor',
    page_name: 'df.base_product_editor_page',
    title: '기본상품관리',
  });
  const st = $.scroll_top(window);
  $.frame.defn_page({
    page_name: 'df.base_product_editor_page',
    tabs: [
      {
        tab_name: 'df.base_product_editor_tab',
        title: '기본상품 정보',
        rendered: () => $.scroll_top2(window, st),
        appended: async function (don_tab) {
          if (box.sel('bp') && box.sel('bp').id) {
            var table = new aTable('.bp_size_table_editor table', {
              mark: {
                icon: {
                  td: false,
                  th: false,
                },
              },
            });

            var table_en = new aTable('.bp_size_table_editor_en table', {
              mark: {
                icon: {
                  td: false,
                  th: false,
                },
              },
            });

            var table_jp = new aTable('.bp_size_table_editor_jp table', {
              mark: {
                icon: {
                  td: false,
                  th: false,
                },
              },
            });
            // var table_line = new aTable('.bp_size_table_editor_line table', {
            //   mark: {
            //     icon: {
            //       td: false,
            //       th: false,
            //     },
            //   },
            // });
            // var table_line_en = new aTable('.bp_size_table_editor_line_en table', {
            //   mark: {
            //     icon: {
            //       td: false,
            //       th: false,
            //     },
            //   },
            // });
          }

          const editor_product_info = new tui.Editor({
            el: $1('#editor_product_info'),
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            height: '300px',
          });
          editor_product_info.setHtml(box.sel('bp->product_info') || '');

          const editor_product_info_en = new tui.Editor({
            el: $1('#editor_product_info_en'),
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            height: '300px',
          });
          editor_product_info_en.setHtml(box.sel('bp->product_info_en') || '');

          const editor_product_info_jp = new tui.Editor({
            el: $1('#editor_product_info_jp'),
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            height: '300px',
          });
          editor_product_info_jp.setHtml(box.sel('bp->product_info_jp') || '');

          const editor_print_guide_pc = new tui.Editor({
            el: $1('#editor_print_guide_pc'),
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            height: '300px',
          });
          editor_print_guide_pc.setHtml(box.sel('bp->print_guide_pc') || '');

          const editor_print_guide_pc_en = new tui.Editor({
            el: $1('#editor_print_guide_pc_en'),
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            height: '300px',
          });
          editor_print_guide_pc_en.setHtml(box.sel('bp->print_guide_pc_en') || '');

          const editor_print_guide_pc_jp = new tui.Editor({
            el: $1('#editor_print_guide_pc_jp'),
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            height: '300px',
          });
          editor_print_guide_pc_jp.setHtml(box.sel('bp->print_guide_pc_jp') || '');

          const editor_print_guide_m = new tui.Editor({
            el: $1('#editor_print_guide_m'),
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            height: '300px',
          });
          editor_print_guide_m.setHtml(box.sel('bp->print_guide_m') || '');

          const editor_print_guide_m_en = new tui.Editor({
            el: $1('#editor_print_guide_m_en'),
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            height: '300px',
          });
          editor_print_guide_m_en.setHtml(box.sel('bp->print_guide_m_en') || '');

          const editor_print_guide_m_jp = new tui.Editor({
            el: $1('#editor_print_guide_m_jp'),
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            height: '300px',
          });
          editor_print_guide_m_jp.setHtml(box.sel('bp->print_guide_m_jp') || '');

          const editor_guide_pc = new tui.Editor({
            el: $1('#editor_guide_pc'),
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            height: '300px',
          });
          editor_guide_pc.setHtml(box.sel('bp->guide_pc') || '');

          const editor_guide_m = new tui.Editor({
            el: $1('#editor_guide_m'),
            initialEditType: 'markdown',
            previewStyle: 'vertical',
            height: '300px',
          });
          editor_guide_m.setHtml(box.sel('bp->guide_m') || '');

          let editor_bpd_tip_description = null;
          let editor_bpd_tip_description_en = null;
          let editor_bpd_tip_description_jp = null;

          if (box.sel('bp->_->base_product_detail->id')) {
            editor_bpd_tip_description = new tui.Editor({
              el: $1('#tip_description'),
              initialEditType: 'markdown',
              previewStyle: 'vertical',
              height: '300px',
            });
            editor_bpd_tip_description.setHtml(box.sel('bp->_->base_product_detail->tip_description') || '');

            editor_bpd_tip_description_en = new tui.Editor({
              el: $1('#tip_description_en'),
              initialEditType: 'markdown',
              previewStyle: 'vertical',
              height: '300px',
            });
            editor_bpd_tip_description_en.setHtml(
              box.sel('bp->_->base_product_detail->tip_description_en') || '',
            );

            editor_bpd_tip_description_jp = new tui.Editor({
              el: $1('#tip_description_jp'),
              initialEditType: 'markdown',
              previewStyle: 'vertical',
              height: '300px',
            });
            editor_bpd_tip_description_jp.setHtml(
              box.sel('bp->_->base_product_detail->tip_description_jp') || '',
            );
          }

          let editor_ness_base_info_kr = null;
          let editor_ness_base_info_en = null;
          let editor_ness_base_info_jp = null;

          if (box.sel('bp->_->bp_additional_infos')?.length) {
            editor_ness_base_info_kr = new tui.Editor({
              el: $1('#ness_base_info_kr'),
              initialEditType: 'markdown',
              previewStyle: 'vertical',
              height: '300px',
            });
            const ness_base_info_kr = find((bp_additional_info) => {
              return bp_additional_info.lang == 'kr';
            }, box.sel('bp->_->bp_additional_infos'));
            editor_ness_base_info_kr.setHtml(ness_base_info_kr?.base_info || '');

            editor_ness_base_info_en = new tui.Editor({
              el: $1('#ness_base_info_en'),
              initialEditType: 'markdown',
              previewStyle: 'vertical',
              height: '300px',
            });
            const ness_base_info_en = find((bp_additional_info) => {
              return bp_additional_info.lang == 'en';
            }, box.sel('bp->_->bp_additional_infos'));
            editor_ness_base_info_en.setHtml(ness_base_info_en?.base_info || '');

            editor_ness_base_info_jp = new tui.Editor({
              el: $1('#ness_base_info_jp'),
              initialEditType: 'markdown',
              previewStyle: 'vertical',
              height: '300px',
            });
            const ness_base_info_jp = find((bp_additional_info) => {
              return bp_additional_info.lang == 'jp';
            }, box.sel('bp->_->bp_additional_infos'));
            editor_ness_base_info_jp.setHtml(ness_base_info_jp?.base_info || '');
          }

          const editor_map = {
            editor_product_info,
            editor_product_info_en,
            editor_product_info_jp,
            editor_print_guide_pc,
            editor_print_guide_pc_en,
            editor_print_guide_pc_jp,
            editor_print_guide_m,
            editor_print_guide_m_en,
            editor_print_guide_m_jp,
            editor_guide_pc,
            editor_guide_m,
            tip_description: editor_bpd_tip_description,
            tip_description_en: editor_bpd_tip_description_en,
            tip_description_jp: editor_bpd_tip_description_jp,
            ness_base_info_kr: editor_ness_base_info_kr,
            ness_base_info_en: editor_ness_base_info_en,
            ness_base_info_jp: editor_ness_base_info_jp,
          };

          const table_map = {
            bp_size_table_editor: table,
            bp_size_table_editor_en: table_en,
            bp_size_table_editor_jp: table_jp,
            // bp_size_table_editor_line: table_line,
            // bp_size_table_editor_line_en: table_line_en,
          };

          if (sessionStorageProxy.bp) {
            if (await $.confirm('작업중인 데이터가 있습니다. 적용할까요?')) {
              setFieldsFromStorage(don_tab, editor_map, table_map);
              removeStorageBp();
            } else {
              removeStorageBp();
            }
          }

          _p.go(
            don_tab,
            tap(
              $findAll('.with_bp_discount_price input'),
              each((el) => {
                if (box().bp._.bp_discount_set?.id) {
                  el.disabled = true;
                }
              }),
            ),
            $.on('input', 'input:not([type="file"]), select, textarea', ({ currentTarget: el }) => {
              editFieldToSession(el, editor_map);
            }),
            $.on('input', '.a-table-wrapper td', ({ currentTarget: el }) => {
              const name_el = go(el, $closest('.a-table-container'), $closest('.a-table-wrapper'), $parent);
              const [from] = go(name_el, $closest('[edit_mode]')).classList;
              const [name] = name_el.classList;
              const value = table_map[name].getTable().replace(/\t|\n/g, '');
              setSession(from, name, value, null);
            }),

            $.on(
              'change',
              '.bp_label_register .control_buttons input[type=file]',
              async ({ currentTarget: ct }) => {
                await uploadBpLabelHandler(ct, box(ct).bp.id);
              },
            ),
            $.on('click', '.bp_label_register .control_buttons button', async ({ currentTarget: ct }) => {
              switch (ct.name) {
                case 'test-print': {
                  try {
                    DfInhouseF.lottie_loader.start('printer');

                    const {
                      id: base_product_id,
                      _: { base_product_colors, base_product_sizes },
                    } = box.sel(ct);

                    const TEST_BPC = base_product_colors[0];
                    const TEST_BPS = base_product_sizes[0];

                    const TEST_UP_C_SS = [
                      {
                        id: `-MARPPLE`,
                        is_hidden: false,
                        quantity: 1,
                        base_product_id,
                        base_product_color_id: TEST_BPS.id,
                        _: { base_product_color: TEST_BPC, base_product_size: TEST_BPS },
                      },
                    ];

                    const labels = DfBpLabelF.createBpLabelFromUpcs({
                      up_c_ss: TEST_UP_C_SS,
                      is_need_full_option_names: false,
                    });
                    if (UtilArrayS.isEmNil(labels)) {
                      $.alert('출력할 상품 고시 정보 데이터가 없습니다. 개발자 문의');
                      DfInhouseF.lottie_loader.end();
                      return;
                    }

                    const product_label_printer = await DfBpLabelF.getValueFromLabelIndexedDB('product');

                    if (product_label_printer == null) {
                      $.alert('[프린터 등록 정보 없음]\n품표 인쇄를 위한 시리얼 번호 등록을 진행해 주세요.');
                      DfInhouseF.lottie_loader.end();
                      return;
                    }

                    const device = await DfWaybillF.prepareLabelPrinter({
                      setting_fn: DfWaybillF.printer_config_fn.product_label,
                      printer_serial: product_label_printer.printer_model,
                    });

                    await DfBpLabelF.printProductLabel({ device, labels });

                    DfInhouseF.lottie_loader.end();
                  } catch (err) {
                    DfInhouseF.lottie_loader.end();
                    await $.alert(
                      `상품 라벨 처리 오류<br>${err?.code ? `에러코드: ${err.code}` : ''}<br>메세지: ${
                        err?.response?.data?.message ?? err?.message ?? '알수 없는 오류'
                      } `,
                    );
                    console.error(err);
                  }
                  break;
                }
                case 'upload': {
                  const input_el = go(ct, $find('input'));
                  input_el.click();
                  break;
                }
                case 'delete': {
                  const { id: base_product_id } = box.sel(ct);
                  if (base_product_id == null) {
                    $.alert('Not exist base_product_id');
                    return;
                  }
                  await deleteBpLabelHandler({ base_product_id });
                  break;
                }
              }
            }),
            $.on('click', '.check-bds button', async () => {
              try {
                const base_product_id = box.sel('bp->id');
                // await DfOMPBpDiscountSetListF.openCheck([base_product_id]);
                const a = document.createElement('a');
                a.href = `/bp_discount_set?base_product_id=${base_product_id}`;
                a.target = '_blank';
                a.click();
              } catch (e) {
                console.error(e);
                $.alert(`ERROR:${e.response?.data || e.message}`);
              } finally {
                $.don_loader_end();
              }
            }),
            $.on('click', '.base_product_detail_tip_copy_btn', async (e) => {
              $.don_loader_start();
              const target_bp_id = go(prompt('복사할 제품의 id를 넣어주세요.'), parseInt);
              if (!Number.isNaN(target_bp_id)) {
                await $.post('/@api/base_product_editor/base_product_detail_tip_copy', {
                  base_product_id: box().bp.id,
                  target_bp_id,
                });
                await $.alert('완료됐습니다.');
                window.location.reload();
              }
              $.don_loader_end();
            }),
            $.on('click', '.btn_mask1_mask2_product_thumb_url_copy', async (e) => {
              $.don_loader_start();
              if (await $.confirm('마스크1,2 대표 썸네일로 모든 색상 면 이미지 적용 진행 하시겠습니까?')) {
                await $.post('/@api/base_product_editor/mask1_mask2_product_thumb_url_copy', {
                  base_product_id: box().bp.id,
                });
                await $.alert('완료됐습니다.');
                window.location.reload();
              }
              $.don_loader_end();
            }),
            $.on('click', '.btn_color_info_copy', async (e) => {
              $.don_loader_start();
              const target_bp_id = go(prompt('복사할 제품의 id를 넣어주세요.'), parseInt);
              if (!Number.isNaN(target_bp_id)) {
                await $.post('/@api/base_product_editor/color_info_copy', {
                  base_product_id: box().bp.id,
                  target_bp_id,
                });
                await $.alert('완료됐습니다.');
                window.location.reload();
              }
              $.don_loader_end();
            }),
            $.on('click', '.btn_bpcfs_all_copy', async (e) => {
              $.don_loader_start();
              const target_bp_id = go(
                prompt(
                  '모든 색상과 그 면 이미지들이 현재 있는 색상 개수에 맞춰서 복사됩니다. 복사할 제품의 id를 넣어주세요.',
                ),
                parseInt,
              );
              if (!Number.isNaN(target_bp_id)) {
                await $.post('/@api/base_product_editor/bpcs_bpcfs_copy', {
                  from_base_product_id: target_bp_id,
                  to_base_product_id: box().bp.id,
                });
                await $.alert('완료됐습니다.');
                window.location.reload();
              }
              $.don_loader_end();
            }),
            $.on('click', '.copy_base_product_detail', async (e) => {
              $.don_loader_start();
              const target_bp_id = go(
                prompt('썸네일, 팁, 설명 모두를 복사할겁니다. 복사할 제품 id를 적어주세요.'),
                parseInt,
              );
              if (!Number.isNaN(target_bp_id)) {
                await $.post('/@api/base_product_editor/base_product_detail_copy', {
                  from_base_product_id: target_bp_id,
                  to_base_product_id: box().bp.id,
                });
                await $.alert('완료됐습니다.');
                window.location.reload();
              }
              $.don_loader_end();
            }),
            $.on('click', '.copy_base_product_detail_tip_description', async (e) => {
              $.don_loader_start();
              const target_bp_id = go(
                prompt('팁 설명만 복사할겁니다. 복사할 제품 id를 적어주세요.'),
                parseInt,
              );
              if (!Number.isNaN(target_bp_id)) {
                await $.post('/@api/base_product_editor/base_product_detail_tip_description_copy', {
                  from_base_product_id: target_bp_id,
                  to_base_product_id: box().bp.id,
                });
                await $.alert('완료됐습니다.');
                window.location.reload();
              }
              $.don_loader_end();
            }),
            $.on('click', '.sync_bpd_thumbnails', async (e) => {
              $.don_loader_start();
              await $.post('/@api/base_product_editor/sync_thumbnails', {
                base_product_id: box().bp.id,
              });
              await $.alert('완료됐습니다.');
              window.location.reload();
              $.don_loader_end();
            }),
            $.on('click', '.bp_faces_editor .bpf_copy', async (e) => {
              $.don_loader_start();
              const target_bp_id = go(
                prompt(
                  '면 정보를 모두 복사합니다. 면 이름이 복사할 제품의 면 이름과 모두 같아야합니다. 복사할 제품 id를 적어주세요.',
                ),
                parseInt,
              );
              if (!Number.isNaN(target_bp_id)) {
                await $.post('/@api/base_product_editor/bpf_copy', {
                  from_base_product_id: target_bp_id,
                  to_base_product_id: box().bp.id,
                });
                await $.alert('완료됐습니다.');
                window.location.reload();
              }
              $.don_loader_end();
            }),
            $.on('click', '.bp_faces_editor .bpf_add', async (e) => {
              $.don_loader_start();
              const ok = await $.confirm(
                '면을 추가하시겠습니까? (삭제가 되지 않습니다. 신중히 선택하여주시기 바랍니다.🙏🏻)',
              );

              const base_product_face_id = go(
                e.currentTarget,
                $closest('.bp_faces_editor'),
                $findAll('.bp_face'),
                last,
                $attr('bpf_id'),
              );

              if (ok) {
                await $.post('/@api/base_product_face/add', { base_product_face_id });
                await $.alert('추가됐습니다.');
                window.location.reload();
              }
              $.don_loader_end();
            }),
            $.on('click', '.bp_size_table_editor .btn_size_table_copy', async (e) => {
              $.don_loader_start();
              const target_bp_id = go(
                prompt('사이즈 표를 복사합니다. 복사할 제품 id를 적어주세요.'),
                parseInt,
              );
              if (!Number.isNaN(target_bp_id)) {
                await $.post('/@api/base_product_editor/btn_size_table_copy', {
                  from_base_product_id: target_bp_id,
                  to_base_product_id: box().bp.id,
                });
                await $.alert('완료됐습니다.');
                window.location.reload();
              }
              $.don_loader_end();
            }),
            $.on('click', '.thumbnail_copy button', async (e) => {
              $.don_loader_start();
              await $.post('/@api/base_product/copy_thumbnail', {
                bp_id: box().bp.id,
              });
              await $.alert('완료됐습니다.');
              window.location.reload();
              $.don_loader_end();
            }),
            $.on('click', '.make_bps_template_meta button', async (e) => {
              const base_product_id = box.sel('bp->id');
              const base_product_size_ids = await go(
                $.get('/@api/prerequisite_maker/base_product_sizes', {
                  base_product_id,
                }),
                pluck('id'),
              );
              const base_product_size_id = prompt(
                `Which Size (${join(',')(base_product_size_ids)})?`,
                base_product_size_ids[0],
              );
              if (!base_product_size_id) return;
              const is_new =
                (await $.confirm('새로 만드십니까?')) && (await $.confirm('정말 새로 만드십니까?'));
              const { template_meta } = is_new
                ? {}
                : await $.get('/@api/prerequisite_maker/base_product_size', {
                    id: base_product_size_id,
                  });
              const base_product_faces = await $.get('/@api/prerequisite_maker/base_product_faces', {
                base_product_id,
              });

              let template_length;
              if (is_new) {
                template_length = parseInt(prompt(`템플릿 개수는 몇개인가요?`, 1));
                if (!template_length) return $.alert('템플릿 개수가 틀렸습니다.');
              }
              await MuiF.openFrame(NewMakerBpsTemplateMetaMuiF.frame, async (frame, page, [tab]) => {
                tab.makeData = () => {
                  return {
                    base_product_size_id,
                    base_product_id,
                    template_meta,
                    base_product_faces,
                    template_length,
                  };
                };
              });
            }),
            $.on('click', '.test_print_img button', async (e) => {
              await downloadTextPrintImgOnly1Face1Size(box.sel('bp'));
            }),
            $.on('click', '.start_make_only_design_face button', async function () {
              if (box().is_user.id !== 193535) return;
              $.don_loader_start();
              await updatePcForOnlyDesignByBpId(box.sel('bp').id);
              $.don_loader_end();
            }),
            $.on('click', '.enter_composite_templates_list button', function () {
              openCompositeTemplatesListFrame(box.sel('bp').id);
            }),
            $.on('click', '.enter_composite_templates_color button', function () {
              openCompositeTemplatesColorFrame(box.sel('bp').id);
            }),
            $.on('click', '.enter_auto_cv_print_area_task button', function () {
              if (box.sel('bp').is_composite_publish)
                return $.alert('이미 합성 템플릿이 출시되어 변경하기 힘듭니다.');
              openAutoCvPrintAreaTaskFrame(box.sel('bp'));
            }),
            $.on('click', '.enter_composite_template_test button', async function () {
              openCompositeTemplateTestFrame(box.sel('bp->id'));
            }),
            $.on('click', '.pod_sku_list', async ({ currentTarget: ct }) => {
              const bp = box.sel(ct);

              if (bp == null || bp?.id == null) {
                await UtilAlertF.error({
                  title: `[개발팀 문의] SKU 를 조회할 상품코드가 존재하지 않습니다.`,
                });
              }

              try {
                await openSkuList({ bp });
              } catch (err) {
                $.don_loader_end();
                console.error(err);
                const err_msg = err.isAxiosError
                  ? err.response.data?.message
                    ? err.response.data.message
                    : err.response.data
                  : err?.message;

                await UtilAlertF.error({
                  title: '오류 발생',
                  msg: `[개발팀 문의]<br>${err_msg ?? '알수 없는 에러 메세지'}`,
                });
              }
            }),
            $.on('click', '.enter_face_task button', async function () {
              if (box.sel('bp').is_composite_publish)
                $.alert('이미 합성 템플릿이 출시됐으니 조심해서 변경해 주세요.');
              alert('사이즈 변경시 이미지 면 이미지의 크기가 변경되면 이 작업을 하시면 안됩니다.');
              // if ([4444, 4445, 4446, 4447, 4448, 4449, 4450, 4451, 4452].includes(box.sel('bp').id)) return $.alert('써지컬 수정 할때, 개발팀에 문의해 주세요.');
              $.don_loader_start();
              await openCvPrintAreaEditor(box.sel('bp->id'));
              $.don_loader_end();
            }),
            $.on('click', '.enter_size_faces_editor button', async function () {
              const base_product_id = box().bp.id;

              const adjust_area_name = 'cv_print_area';

              $.don_loader_start();
              await MuiF.openFrame(NewMakerSizeFacesEditorMuiF.frame, (frame, page, [tab]) => {
                tab.makeData = () => {
                  return {
                    base_product_id,
                    adjust_area_name,
                  };
                };
              });
              $.don_loader_end();
            }),
            $.on('click', '.scroll_to span', function (e) {
              $.scroll_top2(
                $('html, body'),
                $.offset($.find1(don_tab, '.' + $.attr(e.$currentTarget, 'class_name'))).top - 100 || 0,
                0,
              );
            }),
          );

          _p.go(
            $('#bp_editor'),
            // $on_selected_category_events,
            $.on('click', '.bp_info .copy_base_product', async (e) => {
              $.don_loader_start();
              const target_bp_id = go(
                prompt('많은 정보들이 복사 되어 현재 있는것들을 덮어씁니다. 복사할 제품의 id를 넣어주세요.'),
                parseInt,
              );
              if (!Number.isNaN(target_bp_id)) {
                await $.post('/@api/base_product_editor/base_product_copy', {
                  base_product_id: box().bp.id,
                  target_bp_id,
                });
                await $.alert('완료됐습니다.');
                window.location.reload();
              }
              $.don_loader_end();
            }),
            $.on(
              'change',
              '.bp_info .brand_logo input.brand_logo_input',
              ___(_p.v('$currentTarget'), function (input) {
                // 브랜드 로고 이미지 추가
                $.don_loader_start();
                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/bp_brand_logo/file_upload',
                    data: {
                      base_product_id: box.sel('bp').id,
                    },
                  }),
                  function (bp) {
                    box.set('bp->brand_logo', bp.brand_logo);
                    $.attr($.find1($.closest(input, '.brand_logo'), 'img'), 'src', bp.brand_logo);
                    $.show($.find1($.closest(input, '.brand_logo'), '.img'));
                    $.don_loader_end();
                  },
                );
              }),
            ),

            $.on('click', '.bp_info .btn_brand_logo', function (e) {
              $.find1($.closest(e.$currentTarget, '.brand_logo'), 'input.brand_logo_input').click();
            }),

            $.on(
              'change',
              '.bp_info .nep_url input.bp_nep_url',
              ___(_p.v('currentTarget'), function (input) {
                // 네이버 썸네일 추가
                $.don_loader_start();
                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/bp_nep_url/file_upload',
                    data: {
                      base_product_id: box.sel('bp').id,
                    },
                  }),
                  function (bp) {
                    box.set('bp->nep_url', bp.nep_url);
                    $.attr($.find1($.closest(input, '.nep_url'), 'img'), 'src', bp.nep_url);
                    $.show($.find1($.closest(input, '.nep_url'), '.img'));
                    $.don_loader_end();
                  },
                );
              }),
            ),

            $.on('click', '.bp_info .nep_url .btn_add_nep_url', function (e) {
              $.find1($.closest(e.$currentTarget, '.nep_url'), 'input.bp_nep_url').click();
            }),

            $.on(
              'change',
              '.bp_info .size_info_url input.bp_size_info_url',
              ___(_p.v('currentTarget'), function (input) {
                // 네이버 썸네일 추가
                $.don_loader_start();
                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/bp_size_info_url/file_upload',
                    data: {
                      base_product_id: box.sel('bp').id,
                    },
                  }),
                  function (bp) {
                    box.set('bp->size_info_url', bp.nep_url);
                    $.attr($.find1($.closest(input, '.size_info_url'), 'img'), 'src', bp.size_info_url);
                    $.show($.find1($.closest(input, '.size_info_url'), '.img'));
                    $.don_loader_end();
                  },
                );
              }),
            ),

            $.on('click', '.bp_info .size_info_url .btn_size_info_url', function (e) {
              $.find1($.closest(e.$currentTarget, '.size_info_url'), 'input.bp_size_info_url').click();
            }),

            $.on2(
              'change',
              '.bp_info .tmpl_file_url input.bp_tmpl_file_url[type="file"]',
              ___(_p.v('$currentTarget'), function (input) {
                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/base_products/tmpl_file_url',
                    data: { base_product_id: box.sel('bp').id },
                  }),
                  function (data) {
                    _p.go(
                      input,
                      $.closest('.tmpl_file_url'),
                      $.find1('.tmpl_file_url_txt'),
                      $.html(legacyHtml`
                        <a href="${data.tmpl_file_url}" target="_blank">템플릿 파일 다운로드</a>
                      `),
                    );
                  },
                );
              }),
            ),

            $.on('click', '.bp_info .tmpl_file_url .btn_add_tmpl_file', function (e) {
              $.find1($.closest(e.$currentTarget, '.tmpl_file_url'), 'input.bp_tmpl_file_url').click();
            }),

            $.on(
              'change',
              '.seo_metas .seo_img input.bp_seo_img',
              ___(_p.v('currentTarget'), function (input) {
                $.don_loader_start();

                return _p.go($.upload(input), async function (seo_img) {
                  if (!seo_img) return $.alert('파일 업로드 실패');
                  const converted_og_img = await makeOgImageUrlFromUrl(seo_img.url);
                  $.attr($.find1($.closest(input, '.seo_img'), 'img'), 'src', converted_og_img);

                  $.show($.find1($.closest(input, '.seo_img'), '.img'));
                  $.don_loader_end();
                });
              }),
            ),

            $.on('click', '.seo_metas .seo_img .btn_add_seo_img', function (e) {
              $.find1($.closest(e.$currentTarget, '.seo_img'), 'input.bp_seo_img').click();
            }),

            $.on(
              'change',
              '.bp_info .gmc_url input.bp_gmc_url',
              ___(_p.v('currentTarget'), function (input) {
                // 네이버 썸네일 추가
                $.don_loader_start();
                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/bp_thumbs/file_upload',
                    data: {
                      base_product_id: box.sel('bp').id,
                      key: 'gmc_url',
                    },
                  }),
                  function (bp) {
                    if (!bp) return $.alert('파일 업로드 실패');

                    box.set('bp->gmc_url', bp.gmc_url);
                    $.attr($.find1($.closest(input, '.gmc_url'), 'img'), 'src', bp.gmc_url);
                    $.show($.find1($.closest(input, '.gmc_url'), '.img'));
                    $.don_loader_end();
                  },
                );
              }),
            ),

            $.on('click', '.bp_info .gmc_url .btn_add_gmc_url', function (e) {
              $.find1($.closest(e.$currentTarget, '.gmc_url'), 'input.bp_gmc_url').click();
            }),

            $.on(
              'change',
              '.bp_info .gmc_url_en input.bp_gmc_url_en',
              ___(_p.v('currentTarget'), function (input) {
                // 네이버 썸네일 추가
                $.don_loader_start();
                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/bp_thumbs/file_upload',
                    data: {
                      base_product_id: box.sel('bp').id,
                      key: 'gmc_url_en',
                    },
                  }),
                  function (bp) {
                    if (!bp) return $.alert('파일 업로드 실패');

                    box.set('bp->gmc_url_en', bp.gmc_url_en);
                    $.attr($.find1($.closest(input, '.gmc_url_en'), 'img'), 'src', bp.gmc_url_en);
                    $.show($.find1($.closest(input, '.gmc_url_en'), '.img'));
                    $.don_loader_end();
                  },
                );
              }),
            ),

            $.on('click', '.bp_info .gmc_url_en .btn_add_gmc_url_en', function (e) {
              $.find1($.closest(e.$currentTarget, '.gmc_url_en'), 'input.bp_gmc_url_en').click();
            }),

            $.on(
              'change',
              '.bp_info .gmc_url_jp input.bp_gmc_url_jp',
              ___(_p.v('currentTarget'), function (input) {
                // 네이버 썸네일 추가
                $.don_loader_start();
                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/bp_thumbs/file_upload',
                    data: {
                      base_product_id: box.sel('bp').id,
                      key: 'gmc_url_jp',
                    },
                  }),
                  function (bp) {
                    if (!bp) return $.alert('파일 업로드 실패');

                    box.set('bp->gmc_url_jp', bp.gmc_url_jp);
                    $.attr($.find1($.closest(input, '.gmc_url_jp'), 'img'), 'src', bp.gmc_url_jp);
                    $.show($.find1($.closest(input, '.gmc_url_jp'), '.img'));
                    $.don_loader_end();
                  },
                );
              }),
            ),

            $.on('click', '.bp_info .gmc_url_jp .btn_add_gmc_url_jp', function (e) {
              $.find1($.closest(e.$currentTarget, '.gmc_url_jp'), 'input.bp_gmc_url_jp').click();
            }),

            $.on2('click', '.bp_info .btn_delete_brand_logo', function (e) {
              if (!confirm('로고 이미지를 삭제하시겠습니까?')) return;

              return _p.go(
                $.delete('/@api/bp_brand_logo/delete', {
                  base_product_id: box.sel('bp->id'),
                }),
                function () {
                  $.hide($.find1($.closest(e.$currentTarget, '.brand_logo'), '.img'));
                  $.attr($.find1($.closest(e.$currentTarget, '.brand_logo'), '.img img'), 'src', '');
                },
              );
            }),

            $.on2('click', '.bpd_tip_description .btn_save_tip', function () {
              return _p.go(
                $.put('/@api/base_product_detail', {
                  base_product_detail_id: box.sel('bp->_->base_product_detail->id'),
                  version_timestamp: box.sel('bp->_->base_product_detail->version_timestamp'),
                  tip_description: editor_bpd_tip_description.getHtml(),
                  tip_description_md: editor_bpd_tip_description.getValue(),
                }),
                function (data) {
                  if (!data.result) return $.alert('저장되지 않았습니다.');
                  $.alert('국문 tip 설명이 저장되었습니다.');
                  removeStorageBp();
                  box.extend2('bp->_->base_product_detail', data.bpd);
                },
              );
            }),
            $.on2('click', '.bpd_tip_description_en .btn_save_tip_en', function () {
              return _p.go(
                $.put('/@api/base_product_detail', {
                  base_product_detail_id: box.sel('bp->_->base_product_detail->id'),
                  version_timestamp: box.sel('bp->_->base_product_detail->version_timestamp'),
                  tip_description_en: editor_bpd_tip_description_en.getHtml(),
                  tip_description_md_en: editor_bpd_tip_description_en.getValue(),
                }),
                function (data) {
                  if (!data.result) return $.alert('저장되지 않았습니다.');
                  $.alert('영문 tip 설명이 저장되었습니다.');
                  removeStorageBp();
                  box.extend2('bp->_->base_product_detail', data.bpd);
                },
              );
            }),
            $.on2('click', '.bpd_tip_description_jp .btn_save_tip_jp', function () {
              return _p.go(
                $.put('/@api/base_product_detail', {
                  base_product_detail_id: box.sel('bp->_->base_product_detail->id'),
                  version_timestamp: box.sel('bp->_->base_product_detail->version_timestamp'),
                  tip_description_jp: editor_bpd_tip_description_jp.getHtml(),
                  tip_description_md_jp: editor_bpd_tip_description_jp.getValue(),
                }),
                function (data) {
                  if (!data.result) return $.alert('저장되지 않았습니다.');
                  $.alert('일문 tip 설명이 저장되었습니다.');
                  removeStorageBp();
                  box.extend2('bp->_->base_product_detail', data.bpd);
                },
              );
            }),
            $.on2('click', '.bp_size_table_editor .btn_save_size_info', function () {
              return _p.go(
                $.post('/@api/base_product/update', {
                  id: box.sel('bp->id'),
                  size_table: table.getTable().replace(/\t|\n/g, ''),
                  version_timestamp: box.sel('bp->version_timestamp'),
                }),
                _p(box.extend2, 'bp'),
                function () {
                  removeStorageBp();
                  alert('저장되었습니다.');
                },
              );
            }),
            $.on2('click', '.bp_size_table_editor .btn_delete_size_info', function () {
              if (!confirm('사이즈표를 삭제하시겠습니까?')) return;

              return _p.go(
                $.post('/@api/base_product/update', {
                  id: box.sel('bp->id'),
                  size_table: '',
                }),
                _p(box.extend2, 'bp'),
                function () {
                  alert('삭제되었습니다.');
                },
                function () {
                  location.reload();
                },
              );
            }),
            $.on2('click', '.bp_size_table_editor_en .btn_save_size_info_en', function () {
              return _p.go(
                $.post('/@api/base_product/update', {
                  id: box.sel('bp->id'),
                  size_table_en: table_en.getTable().replace(/\t|\n/g, ''),
                  version_timestamp: box.sel('bp->version_timestamp'),
                }),
                _p(box.extend2, 'bp'),
                function () {
                  removeStorageBp();
                  alert('저장되었습니다.');
                },
              );
            }),
            $.on2('click', '.bp_size_table_editor_en .btn_delete_size_info_en', function () {
              if (!confirm('사이즈표를 삭제하시겠습니까?')) return;

              return _p.go(
                $.post('/@api/base_product/update', {
                  id: box.sel('bp->id'),
                  size_table_en: '',
                }),
                _p(box.extend2, 'bp'),
                function () {
                  alert('삭제되었습니다.');
                },
                function () {
                  location.reload();
                },
              );
            }),
            $.on('click', '.bp_size_table_editor_en .btn_to_inch_str', function (e) {
              $.html(
                $.find1(e.$delegateTarget, '.bp_size_table_editor_en .a-table-wrapper'),
                table_en.getTable().replace(/\t|\n/g, ''),
              );

              const td_cm_sizes = $.find(
                e.delegateTarget,
                '.bp_size_table_editor table td[data-cell-id]:not(.a-table-th)',
              );
              const en_td_cm_sizes = $.find(e.delegateTarget, '.bp_size_table_editor_en table td');

              _p.each(td_cm_sizes, function (cm_size, i) {
                $.text(en_td_cm_sizes[i], util.to_inch_str($.text($.find1(cm_size, 'div'))));
              });

              table_en = new aTable('.bp_size_table_editor_en table', {
                mark: {
                  icon: {
                    td: false,
                    th: false,
                  },
                },
              });
            }),

            $.on2('click', '.bp_size_table_editor_jp .btn_save_size_info_jp', function () {
              return _p.go(
                $.post('/@api/base_product/update', {
                  id: box.sel('bp->id'),
                  size_table_jp: table_jp.getTable().replace(/\t|\n/g, ''),
                  version_timestamp: box.sel('bp->version_timestamp'),
                }),
                _p(box.extend2, 'bp'),
                function () {
                  removeStorageBp();
                  alert('저장되었습니다.');
                },
              );
            }),
            $.on2('click', '.bp_size_table_editor_jp .btn_delete_size_info_jp', function () {
              if (!confirm('사이즈표를 삭제하시겠습니까?')) return;

              return _p.go(
                $.post('/@api/base_product/update', {
                  id: box.sel('bp->id'),
                  size_table_jp: '',
                }),
                _p(box.extend2, 'bp'),
                function () {
                  alert('삭제되었습니다.');
                },
                function () {
                  location.reload();
                },
              );
            }),
            // $.on2('click', '.bp_size_table_editor_line .btn_save_size_info_line', function () {
            //   return _p.go(
            //     $.post('/@api/base_product/update', {
            //       id: box.sel('bp->id'),
            //       size_table_line: table_line.getTable().replace(/\t|\n/g, ''),
            //       version_timestamp: box.sel('bp->version_timestamp'),
            //     }),
            //     _p(box.extend2, 'bp'),
            //     function () {
            //       removeStorageBp();
            //       alert('저장되었습니다.');
            //     },
            //   );
            // }),
            // $.on2('click', '.bp_size_table_editor_line .btn_delete_size_info_line', function () {
            //   if (!confirm('사이즈표를 삭제하시겠습니까?')) return;
            //
            //   return _p.go(
            //     $.post('/@api/base_product/update', {
            //       id: box.sel('bp->id'),
            //       size_table_line: '',
            //     }),
            //     _p(box.extend2, 'bp'),
            //     function () {
            //       alert('삭제되었습니다.');
            //     },
            //     function () {
            //       location.reload();
            //     },
            //   );
            // }),
            // $.on2('click', '.bp_size_table_editor_line_en .btn_save_size_info_line_en', function () {
            //   return _p.go(
            //     $.post('/@api/base_product/update', {
            //       id: box.sel('bp->id'),
            //       size_table_line_en: table_line_en.getTable().replace(/\t|\n/g, ''),
            //       version_timestamp: box.sel('bp->version_timestamp'),
            //     }),
            //     _p(box.extend2, 'bp'),
            //     function () {
            //       removeStorageBp();
            //       alert('저장되었습니다.');
            //     },
            //   );
            // }),
            // $.on2('click', '.bp_size_table_editor_line_en .btn_delete_size_info_line_en', function () {
            //   if (!confirm('사이즈표를 삭제하시겠습니까?')) return;
            //
            //   return _p.go(
            //     $.post('/@api/base_product/update', {
            //       id: box.sel('bp->id'),
            //       size_table_line_en: '',
            //     }),
            //     _p(box.extend2, 'bp'),
            //     function () {
            //       alert('삭제되었습니다.');
            //     },
            //     function () {
            //       location.reload();
            //     },
            //   );
            // }),

            $.on(
              'click',
              '>.bp_info .options button.create',
              __(
                _p.v('$delegateTarget'),
                (lo.to_attrs = function (parent) {
                  return {
                    id: box.sel('bp').id,
                    name: $.val($.find1(parent, '.bp_name')),
                    name_en: $.val($.find1(parent, '.bp_name_en')),
                    name_jp: $.val($.find1(parent, '.bp_name_jp')),
                    product_info: editor_product_info.getHtml(),
                    product_info_en: editor_product_info_en.getHtml(),
                    product_info_jp: editor_product_info_jp.getHtml(),
                    product_info_md: editor_product_info.getValue(),
                    product_info_md_en: editor_product_info_en.getValue(),
                    product_info_md_jp: editor_product_info_jp.getValue(),
                    print_guide_pc: editor_print_guide_pc.getHtml(),
                    print_guide_pc_en: editor_print_guide_pc_en.getHtml(),
                    print_guide_pc_jp: editor_print_guide_pc_jp.getHtml(),
                    print_guide_m: editor_print_guide_m.getHtml(),
                    print_guide_m_en: editor_print_guide_m_en.getHtml(),
                    print_guide_m_jp: editor_print_guide_m_jp.getHtml(),
                    guide_pc: editor_guide_pc.getHtml(),
                    guide_m: editor_guide_m.getHtml(),
                    one_line_description: $.val($.find1(parent, '.bp_one_line_description')) || void 0,
                    one_line_description_en: $.val($.find1(parent, '.bp_one_line_description_en')) || void 0,
                    one_line_description_jp: $.val($.find1(parent, '.bp_one_line_description_jp')) || void 0,
                    short_description: $.val($.find1(parent, '.bp_short_description')) || '',
                    short_description_en: $.val($.find1(parent, '.bp_short_description_en')) || '',
                    short_description_jp: $.val($.find1(parent, '.bp_short_description_jp')) || '',
                    // name_line: $.val($.find1(parent, '.bp_name_line')) || '',
                    // name_line_en: $.val($.find1(parent, '.bp_name_line_en')) || '',
                    // name_line_jp: $.val($.find1(parent, '.bp_name_line_jp')) || '',
                    name_creator: $.val($.find1(parent, '.bp_name_creator')) || '',
                    name_creator_en: $.val($.find1(parent, '.bp_name_creator_en')) || '',
                    name_creator_jp: $.val($.find1(parent, '.bp_name_creator_jp')) || '',
                    gmc_title: $.val($.find1(parent, '.bp_gmc_title')) || '',
                    gmc_title_en: $.val($.find1(parent, '.bp_gmc_title_en')) || '',
                    gmc_title_jp: $.val($.find1(parent, '.bp_gmc_title_jp')) || '',
                    description: $.val($.find1(parent, '.bp_description')) || '',
                    description_en: $.val($.find1(parent, '.bp_description_en')) || '',
                    description_jp: $.val($.find1(parent, '.bp_description_jp')) || '',
                    comment: $.val($.find1(parent, '.bp_comment')),

                    original_price: $.val($.find1(parent, '.bp_original_price')) || 0,
                    price: $.val($.find1(parent, '.bp_price')) || 0,
                    print_price: $.val($.find1(parent, '.bp_print_price')) || 0,
                    percent: $.val($.find1(parent, '.bp_percent')) || 0,
                    print_percent: $.val($.find1(parent, '.bp_print_percent')) || 0,

                    original_price_en: $.val($.find1(parent, '.bp_original_price_en')) || 0,
                    price_en: $.val($.find1(parent, '.bp_price_en')) || 0,
                    print_price_en: $.val($.find1(parent, '.bp_print_price_en')) || 0,
                    percent_en: $.val($.find1(parent, '.bp_percent_en')) || 0,
                    print_percent_en: $.val($.find1(parent, '.bp_print_percent_en')) || 0,

                    original_price_jp: $.val($.find1(parent, '.bp_original_price_jp')) || 0,
                    price_jp: $.val($.find1(parent, '.bp_price_jp')) || 0,
                    print_price_jp: $.val($.find1(parent, '.bp_print_price_jp')) || 0,
                    percent_jp: $.val($.find1(parent, '.bp_percent_jp')) || 0,
                    print_percent_jp: $.val($.find1(parent, '.bp_print_percent_jp')) || 0,

                    original_price_creator: $.val($.find1(parent, '.bp_original_price_creator')) || 0,
                    price_creator: $.val($.find1(parent, '.bp_price_creator')) || 0,
                    print_price_creator: $.val($.find1(parent, '.bp_print_price_creator')) || 0,
                    percent_creator: $.val($.find1(parent, '.bp_percent_creator')) || 0,
                    print_percent_creator: $.val($.find1(parent, '.bp_print_percent_creator')) || 0,

                    original_price_creator_en: $.val($.find1(parent, '.bp_original_price_creator_en')) || 0,
                    price_creator_en: $.val($.find1(parent, '.bp_price_creator_en')) || 0,
                    print_price_creator_en: $.val($.find1(parent, '.bp_print_price_creator_en')) || 0,
                    percent_creator_en: $.val($.find1(parent, '.bp_percent_creator_en')) || 0,
                    print_percent_creator_en: $.val($.find1(parent, '.bp_print_percent_creator_en')) || 0,

                    original_price_creator_jp: $.val($.find1(parent, '.bp_original_price_creator_jp')) || 0,
                    price_creator_jp: $.val($.find1(parent, '.bp_price_creator_jp')) || 0,
                    print_price_creator_jp: $.val($.find1(parent, '.bp_print_price_creator_jp')) || 0,
                    percent_creator_jp: $.val($.find1(parent, '.bp_percent_creator_jp')) || 0,
                    print_percent_creator_jp: $.val($.find1(parent, '.bp_print_percent_creator_jp')) || 0,
                    oem_price_creator: $.val($.find1(parent, '.bp_oem_price_creator')) || 0,
                    oem_print_price_creator: $.val($.find1(parent, '.bp_oem_print_price_creator')) || 0,

                    brand_name: $.val($.find1(parent, '.bp_brand_name')) || '',
                    brand_name_en: $.val($.find1(parent, '.bp_brand_name_en')) || '',
                    brand_name_jp: $.val($.find1(parent, '.bp_brand_name_jp')) || '',
                    brand_description: $.val($.find1(parent, '.bp_brand_description')) || '',
                    brand_description_en: $.val($.find1(parent, '.bp_brand_description_en')) || '',
                    brand_description_jp: $.val($.find1(parent, '.bp_brand_description_jp')) || '',
                    nep_search: $.val($.find1(parent, '.bp_nep_search')) || '',
                    naver_category: $.val($.find1(parent, '.bp_naver_category')) || '',
                    company: $.val($.find1(parent, '.bp_company')) || '',
                    company_pd_name: $.val($.find1(parent, '.bp_company_pd_name')) || '',
                    // model_id_samsung: $.val($.find1(parent, '.bp_model_id_samsung')) || null,
                    // is_thumb_samsung: $.find1(parent, '.bp_is_thumb_samsung').checked,
                    is_use: $.find1(parent, '.bp_is_use').checked,
                    is_use_en: $.find1(parent, '.bp_is_use_en').checked,
                    is_use_jp: $.find1(parent, '.bp_is_use_jp').checked,
                    // is_use_line: $.find1(parent, '.bp_is_use_line').checked,
                    // is_use_line_en: $.find1(parent, '.bp_is_use_line_en').checked,
                    // is_use_line_jp: $.find1(parent, '.bp_is_use_line_jp').checked,
                    is_use_creator: $.find1(parent, '.bp_is_use_creator').checked,
                    is_use_creator_en: $.find1(parent, '.bp_is_use_creator_en').checked,
                    is_use_creator_jp: $.find1(parent, '.bp_is_use_creator_jp').checked,
                    // can_rotate: $.find1(parent, '.bp_can_rotate').checked,
                    has_template: $.find1(parent, '.bp_has_template').checked,
                    can_embro: $.find1(parent, '.bp_can_embro').checked,
                    can_flex: $.find1(parent, '.bp_can_flex').checked,
                    can_overflow: $.find1(parent, '.bp_can_overflow').checked,
                    is_bpc_hidden: $.find1(parent, '.bp_is_bpc_hidden').checked,
                    is_bps_hidden: $.find1(parent, '.bp_is_bps_hidden').checked,
                    is_bpf_hidden: $.find1(parent, '.bp_is_bpf_hidden').checked,
                    can_full_cover: $.find1(parent, '.bp_can_full_cover').checked,
                    is_public: !$.find1(parent, '.bp_is_public').checked,
                    is_composite_publish: $.find1(parent, '.bp_is_composite_publish').checked,
                    task_set_id: $.val($.find1(parent, '.bp_task_set_id')) || null,
                    confirm_popup_id: $.val($.find1(parent, '.bp_confirm_popup_id')) || null,
                    df_name_color: $.val($.find1(parent, '.bp_df_name_color')) || null,
                    print_type: $.find1(parent, '.bp_print_type').value,
                    thumbnail_ratio: parseFloat($.val($.find1(parent, '.bp_thumbnail_ratio'))),
                    is_need_line: $.find1(parent, '.bp_is_need_line').checked,
                    is_need_full_cover: $.find1(parent, '.bp_is_need_full_cover').checked,
                    is_stock_team_manage: $.find1(parent, '.bp_is_stock_team_manage').checked,
                    supplier_id: $.val($.find1(parent, '.supplier_id')),
                    hs_code: $.val($.find1(parent, '.bp_hs_code')),
                    production_period: $.val($.find1(parent, '.bp_production_period')),
                    production_fast_period: $.val($.find1(parent, '.bp_production_fast_period')),
                    is_color_for_list: $.find1(parent, '.bp_is_color_for_list').checked,
                    is_size_for_list: $.find1(parent, '.bp_is_size_for_list').checked,
                    width: $.find1(parent, '#bp_width').value || null,
                    height: $.find1(parent, '#bp_height').value || null,
                    length: $.find1(parent, '#bp_length').value || null,
                    weight: $.find1(parent, '#bp_weight').value || null,
                    material: $.find1(parent, '#bp_material_detail').value || null,
                    nexon_base_products: $.find1(parent, '.bp_nexon_base_products').checked,
                  };
                }),
                (lo.to_attrs_check = function (data) {
                  const failBpInfoSaveF = (msg) =>
                    _p.go(
                      void 0,
                      () => $.alert(msg),
                      () => Promise.reject(new Error(msg)),
                    );

                  if (data.is_use && data.original_price <= 0) return failBpInfoSaveF('기본 상품 가격 체크');
                  if (data.is_use_en && data.original_price_en <= 0)
                    return failBpInfoSaveF('영문 기본 상품 가격 체크');
                  if (data.is_use_jp && data.original_price_jp <= 0)
                    return failBpInfoSaveF('일문 기본 상품 가격 체크');
                  if (data.is_use_creator && data.original_price_creator <= 0)
                    return failBpInfoSaveF('마플샵 기본 상품 가격 체크');
                  if (data.is_use_creator_en && data.original_price_creator_en <= 0)
                    return failBpInfoSaveF('마플샵 영문 기본 상품 가격 체크');
                  if (data.is_use_creator_jp && data.original_price_creator_jp <= 0)
                    return failBpInfoSaveF('마플샵 일문 기본 상품 가격 체크');
                  // if (data.is_use_line && data.original_price_line <= 0)
                  //   return failBpInfoSaveF('라인 기본 상품 가격 체크');
                  // if (data.is_use_line_en && data.original_price_line_en <= 0)
                  //   return failBpInfoSaveF('라인 영문 기본 상품 가격 체크');
                  // if (data.is_use_line_jp && data.original_price_line_jp <= 0)
                  //   return failBpInfoSaveF('라인 일문 기본 상품 가격 체크');

                  return data;
                }),
                _p($.post, '/@api/base_product/create'),
                _p.location.replace('/base_product/editor?id={{$.id}}'),
                _p.catch(function (err) {
                  $.alert('create 에러');
                  console.error(err, 'create 에러');
                }),
              ),
            ),

            function (cont) {
              bp_textarea_length($.find1(cont, '.bp_info .description textarea'), '.description');
              bp_textarea_length($.find1(cont, '.bp_info .description_en textarea'), '.description_en');

              return cont;
            },

            $.on('keyup', '.description textarea', function (e) {
              bp_textarea_length(e.$currentTarget, '.description');
            }),

            $.on('keyup', '.description_en textarea', function (e) {
              bp_textarea_length(e.$currentTarget, '.description_en');
            }),

            $.on('click', 'button.btn_edit_mode', async function (e) {
              if ($.has_class(e.currentTarget, 'on')) {
                $.remove_class(e.currentTarget, 'on');
                return _go(e.currentTarget, $.closest('[edit_mode]'), $.attr('edit_mode', 'false'));
              }

              if (
                _go(e.currentTarget, $.closest('[edit_mode]'), $.has_class('bp_colors_editor')) &&
                !(await $.confirm(
                  '이미지 또는 색상을 변경하면 위험 할 수 있습니다.<br>반드시 개발팀에 문의 후에 수정모드를 시작해 주세요.',
                ))
              ) {
                return;
              }

              $.add_class(e.currentTarget, 'on');
              _go(e.currentTarget, $.add_class('on'), $.closest('[edit_mode]'), $.attr('edit_mode', 'true'));
            }),

            $.on('input', '.bp_material input#bp_material_detail', ({ currentTarget: ct }) => {
              ct.value = UtilStringS.sanitizeInputEnglishOnly(ct.value)?.toUpperCase();
            }),

            $.on('change', '.bp_measure_editor input', function (e) {
              const bp_measure_editor = $.closest(e.currentTarget, '.bp_measure_editor');
              const width = $.find1(bp_measure_editor, '#bp_width').value || null;
              const height = $.find1(bp_measure_editor, '#bp_height').value || null;
              const length = $.find1(bp_measure_editor, '#bp_length').value || null;
              const weight = $.find1(bp_measure_editor, '#bp_weight').value || null;
              const volumetric_weight = $.find1(bp_measure_editor, '#bp_volumetric_weight');

              volumetric_weight.value = ShippingS.getVolumetricWeightBySku({
                sku: { width, height, length, weight },
              });
            }),

            $.on(
              'click',
              '>.bp_info .options button.save',
              __(
                _p.tap(function (e) {
                  _p.go(e.currentTarget, $.closest('.bp_info'), filterBlur);
                }),
                _p.v('$delegateTarget'),
                lo.to_attrs,
                lo.to_attrs_check,
                guardSkuMeasurement,
                (e) =>
                  _p.extend(e, {
                    version_timestamp: box.sel('bp').version_timestamp,
                  }),
                async (bp) => {
                  if (
                    !(await isPassSamePrice(
                      [
                        { key: '할인된 가격', v: 'price' },
                        {
                          key: '기본 인쇄 가격',
                          v: 'print_price',
                        },
                        {
                          key: '할인폭 조정',
                          v: 'percent',
                        },
                        {
                          key: '인쇄 학인폭 조정',
                          v: 'print_percent',
                        },
                      ],
                      bp,
                    ))
                  ) {
                    throw new Error('취소 됐습니다.');
                  }
                  await $.post('/@api/base_product/update', bp);
                },
                _p(box.extend2, 'bp'),
                function () {
                  const widths = document.querySelectorAll('input[id^="bp_size_width_"]');
                  const heights = document.querySelectorAll('input[id^="bp_size_height_"]');
                  const lengths = document.querySelectorAll('input[id^="bp_size_length_"]');
                  const weights = document.querySelectorAll('input[id^="bp_size_weight_"]');
                  const volumetric_weights = document.querySelectorAll(
                    'input[id^="bp_size_volumetric_weight_"]',
                  );

                  const width = document.querySelector('input[id="bp_width"]');
                  const height = document.querySelector('input[id="bp_height"]');
                  const length = document.querySelector('input[id="bp_length"]');
                  const weight = document.querySelector('input[id="bp_weight"]');
                  const volumetric_weight = document.querySelector('input[id="bp_volumetric_weight"]');

                  const setSizesValue = (value, els) =>
                    go(
                      els,
                      each((el) => (el.value = value)),
                    );

                  if (width) setSizesValue(width.value, widths);
                  if (height) setSizesValue(height.value, heights);
                  if (length) setSizesValue(length.value, lengths);
                  if (weight) setSizesValue(weight.value, weights);
                  if (volumetric_weight) setSizesValue(volumetric_weight.value, volumetric_weights);
                  removeStorageBp();
                  $.alert('저장되었습니다');
                },
                _p.catch(function (err) {
                  $.alert(err.message);
                  console.error(err, '업데이트 에러');
                }),
              ),
            ),

            $.on('click', '>.bp_info .options button.remove', function () {
              if (!confirm('삭제하시겠습니까??')) return;

              _p.go(
                $.post(
                  '/@api/base_product/update',
                  _p.extend(
                    {},
                    {
                      id: box.sel('bp->id'),
                      is_hidden: true,
                    },
                  ),
                ),
                function () {
                  location.href = '/base_product/list';
                },
              );
            }),
            $.on('change', '.bp_sizes_editor .bp_size_measure_editor input', (e) => {
              const bp_size_measure_editor = $.closest(e.currentTarget, '.bp_size_measure_editor');

              if (bp_size_measure_editor) {
                const width =
                  bp_size_measure_editor.querySelector('input[id^="bp_size_width_"]').value || null;
                const height =
                  bp_size_measure_editor.querySelector('input[id^="bp_size_height_"]').value || null;
                const length =
                  bp_size_measure_editor.querySelector('input[id^="bp_size_length_"]').value || null;
                const weight =
                  bp_size_measure_editor.querySelector('input[id^="bp_size_weight_"]').value || null;
                const volumetric_weight = bp_size_measure_editor.querySelector(
                  'input[id^="bp_size_volumetric_weight_"]',
                );
                volumetric_weight.value = ShippingS.getVolumetricWeightBySku({
                  sku: { width, height, length, weight },
                });
              }
            }),
            $.on('click', '.bp_faces_editor .bp_option_layer_choice', async (e) => {
              const bp_face = go(e.currentTarget, $closest('.bp_face'), box.sel);
              const bpf_id = bp_face.id;

              const [layers = [], all_bp_options = []] = await Promise.all([
                axios
                  .get('/@api/df/base_product_face/preview_property', {
                    params: {
                      property_name: BP_OPTION_LAYERS,
                      bpf_id,
                    },
                  })
                  .then((res) => res.data || []),
                axios
                  .get(`/@api/df/bp_option_groups?base_product_id=${bp_face.base_product_id}`)
                  .then((res) => {
                    if (res.data) {
                      return res.data._.bp_options || [];
                    }

                    return [];
                  }),
              ]);

              const preview_layers = layers.reduce((acc, layer) => {
                acc[layer.bp_option_id] = layer;
                return acc;
              }, {});

              const selectModal = new SelectModalView({
                all_bp_options,
                bpf_id,
                preview_layers,
              });

              selectModal.show();
            }),
            $.on('click', '.bp_option_layer_choice-delete', async (e) => {
              const $el = go(e.currentTarget, $closest('.bp_face'));
              const bp_face = go($el, box.sel);

              const confirm = window.confirm(
                '옵션에 연결된 이미지를 전체 삭제\n' + '하시겠습니까?\n' + '이 작업은 되돌릴수 없습니다.',
              );

              if (confirm) {
                await axios.delete('/@api/df/base_product_face/preview_property', {
                  data: {
                    property_name: BP_OPTION_LAYERS,
                    bpf_id: bp_face.id,
                  },
                });
                go(e.currentTarget, $addClass('d-none'));
                go(
                  $el,
                  $findAll('.bp_option_layer_choice-img div'),
                  each((el) => $addClass('d-none', el)),
                );
                go(
                  $el,
                  $findAll('.bp_option_layer_choice-img img'),
                  each((el) => $setAttr(['src', '#'], el)),
                );
              }
            }),
            $.on('click', '.bp_faces_editor .grid_print_area_download', async (e) => {
              const bp_face = go(e.currentTarget, $closest('.bp_face'), box.sel);
              const {
                data: { product_img_type, sf, mask_url, mask_url2, safe_area_url },
              } = await axios.get('/@api/composite/get_sf_and_product_url', {
                params: {
                  base_product_face_id: bp_face.id,
                },
              });
              const url = await go(
                makeRedLineMockup({
                  product_img_type,
                  sf,
                  mask_url,
                  mask_url2,
                  safe_area_url,
                }),
                (canvas) => canvas.toDataURL(),
              );
              saveAs(url, `${bp_face.base_product_id}-${bp_face.name}`);
            }),
            $.on(
              'click',
              '.bp_faces_editor .add',
              _p.if2(_p.c('색상별로 면이 같이 추가 됩니다.'), $.confirm)(
                function () {
                  $.don_loader_start();

                  return $.post('/@api/base_product_face/create', {
                    no: 0,
                    name: '',
                    price: 0,
                    base_product_id: box.sel('bp').id,
                  });
                },
                function (bp) {
                  reset_bpfs_bpcs(don_tab, bp);
                  $.don_loader_end();
                },
              ),
            ),
            $.on('click', '.bp_sizes_editor .copy_base_product_size', async (e) => {
              $.don_loader_start();
              const target_bp_id = go(
                prompt(
                  '사이즈 정보를 모두 복사합니다. 사이즈의 국문 긴 이름이 모두 같아야합니다. 복사할 제품 id를 적어주세요.',
                ),
                parseInt,
              );
              if (!Number.isNaN(target_bp_id)) {
                await $.post('/@api/base_product_editor/bps_copy', {
                  from_base_product_id: target_bp_id,
                  to_base_product_id: box().bp.id,
                });
                await $.alert('완료됐습니다.');
                window.location.reload();
              }
              $.don_loader_end();
            }),
            $.on('click', '.bp_sizes_editor .add', async function (e) {
              if (!(await $.confirm('사이즈를 추가하시겠습니까?(삭제 없음)'))) return;
              $.don_loader_start();
              const bp_sizes = $.find1(e.$delegateTarget, '.bp_sizes');
              _p.go(
                $.post('/@api/base_product_sizes/create', {
                  no: 0,
                  name: '',
                  short_name: '',
                  price: 0,
                  base_product_id: box.sel('bp').id,
                }),
                function (data) {
                  box.unshift('bp->_->base_product_sizes', data.bps);
                  return _p.mr(data.bps, box.sel('size_filter_lists'), box.sel('is_user'));
                },
                G.df_$bp_size_item,
                $.el,
                $.prepend_to(bp_sizes),
              );
              $.don_loader_end();
            }),

            $.on('click', '.bp_colors_editor .add', function (e) {
              const bp_sizes = $.find1(e.$delegateTarget, '.bp_colors');
              _p.go(
                'bp',
                box.sel,
                _p.obj2('base_product_id', 'id'),
                function (obj) {
                  return _p.extend(obj, {
                    no: 0,
                  });
                },
                _p($.post, '/@api/base_product_color/create'),
                function (bpc) {
                  box.unshift('bp->_->base_product_colors', bpc);
                  return _p.mr(bpc, box.sel('color_filter_lists'), box.sel('is_user'));
                },
                G.df_$bp_color_item,
                $.el,
                $.prepend_to(bp_sizes),
              );
            }),

            /* bp_additional_info */
            $.on('click', '.bp_additional_info .btn_create_bp_additional_info', function (e) {
              _p.go(
                $.post('/@api/bp_additional_info/create', {
                  base_product_id: box.sel('bp').id,
                }),
                function () {
                  location.reload();
                },
              );
            }),

            $.on2('click', '.bp_additional_info .ness_base_info_btn_save_kr', function () {
              return _p.go(
                $.put('/@api/bp_additional_info/update', {
                  base_product_id: box.sel('bp->id'),
                  lang: 'kr',
                  base_info: editor_ness_base_info_kr.getHtml(),
                  base_info_md: editor_ness_base_info_kr.getValue(),
                }),
                function (bp_additional_infos) {
                  $.alert('NESS 상품 상세 국문 정보가 저장되었습니다.');
                  box.extend2('bp->_->bp_additional_infos', bp_additional_infos);
                },
              );
            }),

            $.on2('click', '.bp_additional_info .ness_base_info_btn_save_en', function () {
              return _p.go(
                $.put('/@api/bp_additional_info/update', {
                  base_product_id: box.sel('bp->id'),
                  lang: 'en',
                  base_info: editor_ness_base_info_en.getHtml(),
                  base_info_md: editor_ness_base_info_en.getValue(),
                }),
                function (bp_additional_infos) {
                  $.alert('NESS 상품 상세 영문 정보가 저장되었습니다.');
                  box.extend2('bp->_->bp_additional_infos', bp_additional_infos);
                },
              );
            }),

            $.on2('click', '.bp_additional_info .ness_base_info_btn_save_jp', function () {
              return _p.go(
                $.put('/@api/bp_additional_info/update', {
                  base_product_id: box.sel('bp->id'),
                  lang: 'jp',
                  base_info: editor_ness_base_info_jp.getHtml(),
                  base_info_md: editor_ness_base_info_jp.getValue(),
                }),
                function (bp_additional_infos) {
                  $.alert('NESS 상품 상세 일문 정보가 저장되었습니다.');
                  box.extend2('bp->_->bp_additional_infos', bp_additional_infos);
                },
              );
            }),

            /* base_product_detail */
            $.on('click', '.detail .add_detail', function (e) {
              _p.go(
                $.post('/@api/base_product_detail/create', {
                  base_product_id: box.sel('bp').id,
                }),
                function () {
                  location.reload();
                },
              );
            }),

            $.on(
              'change',
              '.detail input.file[type="file"]',
              ___(_p.v('$currentTarget'), function (input) {
                // 상품 썸네일, 커스텀 이미지 추가
                const thumb_area = $.closest(input, '.bpd_thumb_area');

                $.don_loader_start();
                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/base_product_detail/file_upload',
                    data: {
                      base_product_detail_id: box.sel('bp->_->base_product_detail').id,
                      base_product_id: box.sel('bp').id,
                      key: $.attr(input, 'name'),
                    },
                  }),
                  function (bpd) {
                    box.set(
                      'bp->_->base_product_detail->' + $.attr(input, 'name'),
                      bpd[$.attr(input, 'name')],
                    );

                    $.html(
                      $.find1(thumb_area, '.list'),
                      G.df_$bpd_thumbnails(box.sel('bp->_->base_product_detail->' + $.attr(input, 'name'))),
                    );

                    $.don_loader_end();
                  },
                );
              }),
            ),

            $.on('click', '.detail .bpd_thumb_area .options button.add_img', function (e) {
              $.find1($.closest(e.$currentTarget, '.options'), 'input[type="file"]').click();
            }),

            $.on('click', '.detail .bpd_thumb_area .options button.order_align', function (e) {
              const thumb_area = $.closest(e.$currentTarget, '.bpd_thumb_area');
              const input = $.find1(thumb_area, 'input[type="file"]');
              const images = box.sel('bp->_->base_product_detail')[$.attr(input, 'name')];

              _p.go(
                images,
                function (images) {
                  return {
                    order_by: 'desc',
                    items: _map(images, function (image) {
                      return {
                        id: '0',
                        url: image.url,
                        no: 0,
                      };
                    }),
                  };
                },
                G.order_align.editor,
                function (items) {
                  if (!items) return;

                  $.don_loader_start();
                  _p.go(
                    $.put('/@api/base_product_detail/thumbnails/order_align', {
                      base_product_id: box.sel('bp').id,
                      base_product_detail_id: box.sel('bp->_->base_product_detail').id,
                      items: _map(items, function (item) {
                        const img = _p.find(images, function (image) {
                          return image.url == item.url;
                        });
                        return {
                          url: item.url,
                          width: img ? img.width || 0 : 0,
                          height: img ? img.height || 0 : 0,
                        };
                      }),
                      key: $.attr(input, 'name'),
                    }),
                    function (data) {
                      if (!data.result) {
                        $.alert('저장되지 않았습니다. 다시 시도해 주세요.');
                        $.don_loader_end();
                        return;
                      }

                      box.set(
                        'bp->_->base_product_detail->' + $.attr(input, 'name'),
                        data.base_product_detail[$.attr(input, 'name')],
                      );

                      $.html(
                        $.find1(thumb_area, '.list'),
                        G.df_$bpd_thumbnails(box.sel('bp->_->base_product_detail->' + $.attr(input, 'name'))),
                      );

                      $.don_loader_end();
                    },
                  );
                },
              );
            }),

            $.on('click', '.detail .bpd_thumb_area span.btn_delete', function (e) {
              const thumb_area = $.closest(e.$currentTarget, '.bpd_thumb_area');
              const input = $.find1(thumb_area, 'input[type="file"]');
              const images = box.sel('bp->_->base_product_detail')[$.attr(input, 'name')];

              _p.go(
                '삭제하시겠습니까?',
                $.confirm,
                _p.if2(_p.idtt)(
                  function () {
                    $.don_loader_start();
                    $.remove($.closest(e.$currentTarget, '.item'));
                  },
                  function () {
                    return $.delete('/@api/base_product_detail/thumbnails', {
                      base_product_id: box.sel('bp').id,
                      base_product_detail_id: box.sel('bp->_->base_product_detail').id,
                      items: _p.map($.find(thumb_area, '.item'), function (item) {
                        const img = _p.find(images, function (image) {
                          return image.url == $.attr($.find1(item, 'img'), 'url');
                        });
                        return {
                          url: $.attr($.find1(item, 'img'), 'url'),
                          width: img ? img.width || 0 : 0,
                          height: img ? img.height || 0 : 0,
                        };
                      }),
                      key: $.attr(input, 'name'),
                    });
                  },
                  function (data) {
                    if (!data.result) {
                      $.alert('저장되지 않았습니다. 다시 시도해 주세요.');
                      $.don_loader_end();
                      return;
                    }

                    box.set(
                      'bp->_->base_product_detail->' + $.attr(input, 'name'),
                      data.base_product_detail[$.attr(input, 'name')],
                    );

                    $.html(
                      $.find1(thumb_area, '.list'),
                      G.df_$bpd_thumbnails(box.sel('bp->_->base_product_detail->' + $.attr(input, 'name'))),
                    );

                    $.don_loader_end();
                  },
                ),
              );
            }),

            $.on2(
              'change',
              '.detail .bp_size_compare input[type="file"]',
              ___(_p.v('$currentTarget'), function (input) {
                // 사이즈 비교 이미지 업로드
                $.don_loader_start();
                return _p.go($.upload(input, { url: '/@fileUpload/file' }), function (file) {
                  $.attr($.find1($.closest(input, '.image_area'), 'img'), 'src', G.to_350(file.url));
                  $.attr($.find1($.closest(input, '.image_area'), 'img'), 'data-url', file.url);
                  $.show($.find1($.closest(input, '.image_area'), '.img'));
                  $.don_loader_end();
                });
              }),
            ),
            $.on('click', '.detail .bp_size_compare[edit_mode="false"] label', (e) => {
              e.preventDefault();
            }),

            $.on2('click', '.detail .bp_size_compare .btn_save', (e) => {
              const size_compare = $.closest(e.currentTarget, '.bp_size_compare');

              return _p.go(
                void 0,
                () => {
                  const items = _p.map($.find(size_compare, '.item'), (item) => {
                    return {
                      url: $.attr($.find1(item, '.img1 img'), 'data-url'),
                      m_url: $.attr($.find1(item, '.img2 img'), 'data-url'),
                      txt1: $.val($.find1(item, '.bp_size_compare_txt1')),
                      txt2: $.val($.find1(item, '.bp_size_compare_txt2')),
                    };
                  });

                  const is_public = _p.every(items, (item) => {
                    return _p.every(_p.values(item), !_p.is_empty);
                  });

                  return $.post('/@api/bpd/size_compare', {
                    base_product_id: box.sel('bp->id'),
                    base_product_detail_id: box.sel('bp->_->base_product_detail->id'),
                    size_compare: JSON.stringify({
                      is_public,
                      items,
                    }),
                  });
                },
                (data) => {
                  if (!data.result) return $.alert('저장되지 않았습니다.');
                  box.set('bp->_->base_product_detail->size_compare', data.bpd.size_compare);
                  return $.alert('저장되었습니다.');
                },
              );
            }),

            /* 면, 사이즈 변경 이벤트 */

            // 값 변경 시 배경색
            $.on('click', '.infos .bp_faces_editor .options .btn_face_order_align', function (e) {
              const faces = box.sel('bp->_->base_product_faces');

              _p.go(
                faces,
                function (faces) {
                  return {
                    type: 'text',
                    order_by: 'asc',
                    items: _map(faces, function (face) {
                      return {
                        id: face.id,
                        url: face.url,
                        name: face.name,
                        no: face.no,
                      };
                    }),
                  };
                },
                G.order_align.editor,
                function (items) {
                  if (!items) return;

                  $.don_loader_start();
                  _p.go(
                    $.put('/@api/base_product_detail/bpfs/order_align', {
                      base_product_id: box.sel('bp').id,
                      base_product_detail_id: box.sel('bp->_->base_product_detail').id,
                      items,
                    }),
                    function (data) {
                      if (!data.result) {
                        $.alert('저장되지 않았습니다. 다시 시도해 주세요.');
                        $.don_loader_end();
                        return;
                      }

                      reset_bpfs_bpcs(don_tab, data.bp);
                      $.don_loader_end();
                    },
                  );
                },
              );
            }),
            $.on('click', '.bp_face .remove_shading_url', async (e) => {
              if (!(await $.confirm('명암 이미지를 삭제 하시겠습니까?'))) return;
              $.don_loader_start();
              await go(e.currentTarget, $closest('.bp_face.parent'), $attr('bpf_id'), (bpf_id) =>
                $.post('/@api/base_product_face/update', {
                  id: bpf_id,
                  shading_url: null,
                }),
              );
              $.don_loader_end();
              await $.alert('새로고침됩니다.');
              window.location.reload();
            }),
            $.on('click', '.bp_face .remove_safe_area_url', async (e) => {
              if (!(await $.confirm('안전영역 이미지를 삭제 하시겠습니까?'))) return;
              $.don_loader_start();
              await go(e.currentTarget, $closest('.bp_face.parent'), $attr('bpf_id'), (bpf_id) =>
                $.post('/@api/base_product_face/update', {
                  id: bpf_id,
                  safe_area_url: null,
                }),
              );
              $.don_loader_end();
              await $.alert('새로고침됩니다.');
              window.location.reload();
            }),
            // 면 리스트 삭제
            $.on(
              'click',
              '.bp_face .remove',
              _p.if2(_p.c('삭제하시겠습니까?'), $.confirm)(
                (lo.e_to_parent = __(_p.v('target'), $.closest('.parent'))),
                function (parent) {
                  $.don_loader_start();
                  _p.go(
                    parent,
                    $.attr('bpf_id'),
                    function (id) {
                      return { id, is_hidden: true };
                    },
                    _p($.delete, '/@api/base_product_face/update'),
                    function (bp) {
                      reset_bpfs_bpcs(don_tab, bp);
                      $.don_loader_end();
                    },
                  );
                },
              ),
            ),

            $.on('click', '.infos .bp_sizes_editor .options .btn_size_order_align', function (e) {
              const bps_editor = $.closest(e.$currentTarget, '.bp_sizes_editor');
              const sizes = box.sel('bp->_->base_product_sizes');

              _p.go(
                sizes,
                function (sizes) {
                  return {
                    type: 'text',
                    order_by: 'asc',
                    items: _map(sizes, function (size) {
                      return {
                        id: size.id,
                        name: size.name,
                        no: size.no,
                      };
                    }),
                  };
                },
                G.order_align.editor,
                function (items) {
                  if (!items) return;

                  $.don_loader_start();
                  _p.go(
                    $.put('/@api/base_product_detail/bpss/order_align', {
                      base_product_id: box.sel('bp').id,
                      base_product_detail_id: box.sel('bp->_->base_product_detail').id,
                      items,
                    }),
                    function (data) {
                      if (!data.result) {
                        $.alert('저장되지 않았습니다. 다시 시도해 주세요.');
                        $.don_loader_end();
                        return;
                      }

                      box.extend2('bp->_->base_product_sizes', data.items);

                      $.html(
                        $.find1(bps_editor, '.bp_sizes'),
                        _map(sizes, function (bps) {
                          return G.df_$bp_size_item(bps, box.sel('size_filter_lists'), box.sel('is_user'));
                        }),
                      );

                      $.don_loader_end();
                    },
                  );
                },
              );
            }),

            // 사이즈 리스트 삭제
            $.on('click', '.bp_size .remove', function (e) {
              const parent = $.closest(e.$currentTarget, '.bp_size');
              if (_p.v(box.sel(parent), 'is_thumb'))
                return $.alert('대표 사이즈는 삭제 할 수 없습니다.<br> 대표 사이즈를 변경 후 이용해 주세요');

              _p.go(
                e,
                _p.if2(_p.c('삭제하시겠습니까?'), $.confirm)(
                  lo.e_to_parent,
                  _p.tap(
                    function (parent) {
                      const bps_id = $.attr(parent, 'bps_id');
                      return (
                        bps_id &&
                        $.post('/@api/base_product_size/delete', {
                          id: bps_id,
                          is_hidden: true,
                        })
                      );
                    },
                    function (data) {
                      if (!data.result) return $.alert('삭제 실패 다시 시도해 주세요.');
                      box.set('bp->_->base_product_sizes', data.bpss);
                    },
                  ),
                  $.remove,
                ),
              );
            }),

            // seo meta 변경
            $.on('click', '>.seo_metas .options button.save', async function (e) {
              try {
                _p.go(e.currentTarget, filterBlur);
                const el = _p.go(e.currentTarget, $.closest('.seo_metas'));
                const meta = {
                  description: $.val($find('input.seo_description', el)),
                  description_jp: $.val($find('input.seo_description_jp', el)),
                  description_en: $.val($find('input.seo_description_en', el)),
                  title: $.val($find('input.seo_title', el)),
                  title_en: $.val($find('input.seo_title_en', el)),
                  title_jp: $.val($find('input.seo_title_jp', el)),
                  og_title: $.val($find('input.seo_og_title', el)),
                  og_title_en: $.val($find('input.seo_og_title_en', el)),
                  og_title_jp: $.val($find('input.seo_og_title_jp', el)),
                  og_img: $attr('src', $find('.img img', el)),
                  type: 'base_product',
                  type_value: box.sel('bp').id,
                };
                const id = box.sel('seo_metas')?.id;
                if (id) {
                  await axios.patch('/@api/seo_meta', { id, meta });
                } else {
                  await axios.post('/@api/seo_meta', { meta });
                }
                $.alert('저장되었습니다');
              } catch (e) {
                $.alert(e);
              }
            }),

            // 면 리스트 변경
            $.on(
              'click',
              '.bp_face .save',
              __(
                lo.e_to_parent,
                async function (parent) {
                  const bpf = G.$form_$to_attrs(parent);
                  if (!(await isPassSamePrice([{ key: '가격', v: 'price' }], bpf))) {
                    throw new Error('취소 됐습니다.');
                  }
                  _p.go(parent, filterBlur);
                  return $.post(
                    '/@api/base_product_face/update',
                    _p.extend(bpf, {
                      id: $.attr(parent, 'bpf_id'),
                      version_timestamp: box.sel(parent).version_timestamp,
                    }),
                  );
                },
                function (bp) {
                  removeStorageBp();
                  reset_bpfs_bpcs(don_tab, bp);
                  $.don_loader_end();
                },
              ),
            ),

            $.on('click', '.bp_faces_editor[edit_mode="false"] .bp_face label', function (e) {
              e.preventDefault();
            }),

            $.on(
              'change',
              '.bp_faces_editor[edit_mode="true"] .bp_face input[type="file"]',
              ___(_p.v('$currentTarget'), function (input) {
                const $bpf = $.closest(input, '.bp_face');
                const $file_area = $.closest(input, '.file_area');

                _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/base_product_face/file_upload',
                    data: {
                      id: $.attr($bpf, 'bpf_id'),
                      base_product_id: box.sel('bp').id,
                      key: $.attr($file_area, 'key'),
                    },
                  }),
                  function (data) {
                    box.set(
                      'bp->_->base_product_faces->(#' +
                        $.attr($bpf, 'bpf_id') +
                        ')->' +
                        $.attr($file_area, 'key'),
                      data[$.attr($file_area, 'key')],
                    );
                    _p.go(
                      $file_area,
                      $.find('img'),
                      $.attr('src', G.to_150(data[$.attr($file_area, 'key')])),
                    );
                  },
                );
              }),
            ),

            // 면 커스텀 정보 편집
            $.on('click', '.bp_face .bpfe_opener', __(lo.e_to_parent, box.sel, G.$bpfe_open)),

            // 사이즈 리스트 변경
            $.on(
              'click',
              '.bp_size .save',
              ___(
                lo.e_to_parent,
                async function (parent) {
                  if (
                    $.find1(parent, 'input[type="radio"]').checked &&
                    !$.find1(parent, 'input[name="is_public"]').checked
                  ) {
                    $.alert(
                      '공개 되지 않은 사이즈는 대표로 지정할 수 없습니다.<br>해당 사이즈를 공개하거나 다른 사이즈를 대표로 먼저 지정한 후 저장해 주세요.',
                    );
                    return void 0;
                  }

                  this.parent = parent;
                  const id = $.attr(parent, 'bps_id');
                  _p.go(parent, filterBlur);

                  return $.post(
                    '/@api/base_product_size/update',
                    _p.extend(G.$form_$to_attrs(parent), {
                      id,
                      is_thumb: $.find1(parent, 'input[type="radio"]').checked,
                      version_timestamp: box.sel(parent).version_timestamp,
                    }),
                  );
                },
                function (bpss) {
                  if (!bpss) return;
                  removeStorageBp();
                  box.set('bp->_->base_product_sizes', bpss);
                },
              ),
            ),

            // 기본상품 대표 썸네일
            $.on2(
              'change',
              '.bp_model_thumb input[type="file"].bp_model_thumb_file',
              ___(_p.v('$currentTarget'), function (input) {
                const bp_thumb = $.closest(input, '.bp_model_thumb');
                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/base_product_model_thumb/update',
                    data: {
                      base_product_id: box.sel('bp').id,
                      key: 'model_thumb_url',
                    },
                  }),
                  function (data) {
                    if (!data.result) return $.alert('썸네일 저장 실패 다시 시도해 주세요.');

                    box.set('bp->model_thumb_url', data.bp.model_thumb_url);
                    _p.go(bp_thumb, $.find1('.thumb img'), $.attr('src', G.to_350(data.bp.model_thumb_url)));
                  },
                );
              }),
            ),
            $.on('click', '.bp_model_thumb .btn_bp_model_thumb', function (e) {
              $.find1($.closest(e.$currentTarget, '.bp_model_thumb'), '.bp_model_thumb_file').click();
            }),

            $.on('click', '.bp_model_thumb .btn_bp_model_thumb_delete', function (e) {
              if (!confirm('대표 썸네일을 삭제하시겠습니까?')) return;
              const bp_thumb = $.closest(e.$currentTarget, '.bp_model_thumb');
              _p.go(
                $.delete('/@api/base_product_model_thumb/delete', {
                  base_product_id: box.sel('bp').id,
                  key: 'model_thumb_url',
                }),
                function (data) {
                  if (!data.result) return $.alert('썸네일 삭제 실패. 다시 시도해 주세요.');

                  box.set('bp->model_thumb_url', null);
                  $.attr($.find1(bp_thumb, '.thumb img'), 'src', '');
                },
              );
            }),

            $.on2(
              'change',
              '.bp_model_thumb_en input[type="file"].bp_model_thumb_file_en',
              ___(_p.v('$currentTarget'), function (input) {
                const bp_thumb = $.closest(input, '.bp_model_thumb_en');
                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/base_product_model_thumb/update',
                    data: {
                      base_product_id: box.sel('bp').id,
                      key: 'model_thumb_url_en',
                    },
                  }),
                  function (data) {
                    if (!data.result) return $.alert('썸네일 저장 실패 다시 시도해 주세요.');

                    box.set('bp->model_thumb_url_en', data.bp.model_thumb_url_en);
                    _p.go(
                      bp_thumb,
                      $.find1('.thumb img'),
                      $.attr('src', G.to_350(data.bp.model_thumb_url_en)),
                    );
                  },
                );
              }),
            ),

            $.on('click', '.bp_model_thumb_en .btn_bp_model_thumb_en', function (e) {
              $.find1($.closest(e.$currentTarget, '.bp_model_thumb_en'), '.bp_model_thumb_file_en').click();
            }),

            $.on('click', '.bp_model_thumb_en .btn_bp_model_thumb_en_delete', function (e) {
              if (!confirm('대표 썸네일을 삭제하시겠습니까?')) return;
              const bp_thumb = $.closest(e.$currentTarget, '.bp_model_thumb_en');
              _p.go(
                $.delete('/@api/base_product_model_thumb/delete', {
                  base_product_id: box.sel('bp').id,
                  key: 'model_thumb_url_en',
                }),
                function (data) {
                  if (!data.result) return $.alert('썸네일 삭제 실패. 다시 시도해 주세요.');
                  box.set('bp->model_thumb_url_en', null);
                  $.attr($.find1(bp_thumb, '.thumb img'), 'src', '');
                },
              );
            }),

            $.on2(
              'change',
              '.bp_model_thumb_jp input[type="file"].bp_model_thumb_file_jp',
              ___(_p.v('$currentTarget'), function (input) {
                const bp_thumb = $.closest(input, '.bp_model_thumb_jp');
                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/base_product_model_thumb/update',
                    data: {
                      base_product_id: box.sel('bp').id,
                      key: 'model_thumb_url_jp',
                    },
                  }),
                  function (data) {
                    if (!data.result) return $.alert('썸네일 저장 실패 다시 시도해 주세요.');

                    box.set('bp->model_thumb_url_jp', data.bp.model_thumb_url_jp);
                    _p.go(
                      bp_thumb,
                      $.find1('.thumb img'),
                      $.attr('src', G.to_350(data.bp.model_thumb_url_jp)),
                    );
                  },
                );
              }),
            ),

            $.on('click', '.bp_model_thumb_jp .btn_bp_model_thumb_jp', function (e) {
              $.find1($.closest(e.$currentTarget, '.bp_model_thumb_jp'), '.bp_model_thumb_file_jp').click();
            }),

            $.on('click', '.bp_model_thumb_jp .btn_bp_model_thumb_jp_delete', function (e) {
              if (!confirm('대표 썸네일을 삭제하시겠습니까?')) return;
              const bp_thumb = $.closest(e.$currentTarget, '.bp_model_thumb_jp');
              _p.go(
                $.delete('/@api/base_product_model_thumb/delete', {
                  base_product_id: box.sel('bp').id,
                  key: 'model_thumb_url_jp',
                }),
                function (data) {
                  if (!data.result) return $.alert('썸네일 삭제 실패. 다시 시도해 주세요.');
                  box.set('bp->model_thumb_url_jp', null);
                  $.attr($.find1(bp_thumb, '.thumb img'), 'src', '');
                },
              );
            }),

            // 색상 리스트 변경
            $.on(
              'click',
              '.bp_color .save',
              ___(
                lo.e_to_parent,
                function (parent) {
                  if (
                    $.find1(parent, 'input[type="radio"]').checked &&
                    !$.find1(parent, 'input[name="is_public"]').checked
                  ) {
                    $.alert(
                      '공개 되지 않은 컬러는 대표로 지정할 수 없습니다.(마플샵 오픈 되어 있으면 대표는 마플샵도 공개여야 합니다.)<br>해당 컬러를 공개로 설정하거나 다른 컬러를 대표로 먼저 지정한 후 저장해 주세요.',
                    );
                    return void 0;
                  }
                  if (
                    box.sel('bp->is_use_creator') &&
                    $.find1(parent, 'input[type="radio"]').checked &&
                    !$.find1(parent, 'input[name="is_public_creator"]').checked
                  ) {
                    $.alert(
                      '마플샵에 오픈 되어 있으면 대표는 마플샵도 공개여야 합니다.<br>해당 컬러를 공개로 설정하거나 다른 컬러를 대표로 먼저 지정한 후 저장해 주세요.',
                    );
                    return void 0;
                  }

                  this.parent = parent;
                  _p.go(parent, filterBlur);
                  // const rgb = go(parent, $find('input[name="rgb"]'), $val, (v) => {
                  //   if (!v) return;
                  //   try {
                  //     if (v.length !== 7) throw new Error('컬러코드가 #000000 모양이 아닙니다.');
                  //     return go(
                  //       v.replace(/#/g, ''),
                  //       (hex) => [
                  //         parseInt(`${hex[0]}${hex[1]}`, 16),
                  //         parseInt(`${hex[2]}${hex[3]}`, 16),
                  //         parseInt(`${hex[4]}${hex[5]}`, 16),
                  //       ],
                  //       (a) => {
                  //         return a;
                  //       },
                  //     );
                  //   } catch (e) {
                  //     $.alert(e.message);
                  //   }
                  // });
                  $.don_loader_start();
                  return $.post(
                    '/@api/base_product_color/update',
                    _p.extend(G.$form_$to_attrs(parent), {
                      id: $.attr($.closest(parent, '.parent'), 'bpc_id'),
                      is_thumb: $.find1(parent, 'input[type="radio"]').checked,
                      version_timestamp: box.sel(parent).version_timestamp,
                    }),
                  );
                },
                function (bp) {
                  if (!bp) return;
                  removeStorageBp();
                  box.set('bp->_->base_product_colors', bp._.base_product_colors);
                  $.don_loader_end();
                },
              ),
            ),

            $.on('click', '.infos .bp_colors_editor .options .btn_bpc_order_align', function (e) {
              const bpc_editor = $.closest(e.$currentTarget, '.bp_colors_editor');
              const bpcs = box.sel('bp->_->base_product_colors');

              _p.go(
                bpcs,
                function (bpcs) {
                  return {
                    type: 'text',
                    order_by: 'asc',
                    items: _map(bpcs, function (bpc) {
                      return {
                        id: bpc.id,
                        name: bpc.name,
                        no: bpc.no,
                      };
                    }),
                  };
                },
                G.order_align.editor,
                function (items) {
                  if (!items) return;

                  $.don_loader_start();
                  _p.go(
                    $.put('/@api/base_product_detail/bpcs/order_align', {
                      base_product_id: box.sel('bp').id,
                      items,
                    }),
                    function (data) {
                      if (!data.result) {
                        $.alert('저장되지 않았습니다. 다시 시도해 주세요.');
                        $.don_loader_end();
                        return;
                      }

                      box.extend2('bp->_->base_product_colors', data.bpcs);

                      $.html(
                        $.find1(bpc_editor, '.bp_colors'),
                        _p.map(
                          bpcs,
                          _p(G.df_$bp_color_item, _p, box.sel('color_filter_lists'), box.sel('is_user')),
                        ),
                      );

                      $.don_loader_end();
                    },
                  );
                },
              );
            }),
            $.on('click', '.bp_color_face .remove_mask2_url', async (e) => {
              if (!(await $.confirm('마스크2를 지웁니다.'))) return;
              $.don_loader_start();
              const bpcf_id = go(e.currentTarget, $closest('.bp_color_face'), $attr('bpcf_id'));
              await $.post('/@api/base_product_color_face/remove_mask2_url', { bpcf_id });
              $.don_loader_end();
              await $.alert('새로고칩됩니다.');
              window.location.reload();
            }),
            // 사이즈 리스트 삭제
            $.on(
              'click',
              '.bp_color .remove',
              _p.if2(
                _p.c('색상을 삭제하시겠습니까?'),
                $.confirm,
              )(function (e) {
                const parent = $.closest(e.$currentTarget, '.bp_color');

                if (_p.v(box.sel(parent), 'is_thumb')) return $.alert('대표 컬러는 삭제 할 수 없습니다.');

                _p.go(
                  $.post('/@api/base_product_color/delete', {
                    id: $.attr(parent, 'bpc_id'),
                    is_hidden: true,
                  }),
                  function (data) {
                    if (!data.result) return $.alert('컬러삭제 실패 다시 시도해 주세요.');
                    $.remove(parent);
                    box.set(
                      'bp->_->base_product_colors',
                      _p.v(data.bp, '_.base_product_colors') ? _p.v(data.bp, '_.base_product_colors') : [],
                    );
                  },
                );
              }),
            ),

            $.on('click', '.bp_colors_editor[edit_mode="false"] .bp_color label', function (e) {
              e.preventDefault();
            }),

            $.on(
              'change',
              '.bp_color input[type="file"].bp_color_thumb_file',
              ___(_p.v('$currentTarget'), function (input) {
                const bpc = $.closest(input, '.bp_color');

                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/base_product_color_thumb/update/' + _p.go(bpc, $.attr('bpc_id')),
                    data: {
                      base_product_id: box.sel('bp').id,
                    },
                  }),
                  function (data) {
                    box.set(
                      'bp->_->base_product_colors->(#' + $.attr(bpc, 'bpc_id') + ')->product_thumb_url',
                      data.product_thumb_url,
                    );
                    box.set(
                      'bp->_->base_product_colors->(#' + $.attr(bpc, 'bpc_id') + ')->product_thumb_id',
                      data.product_thumb_id,
                    );

                    _p.go(
                      bpc,
                      $.find('.bp_color_thumb .thumb img'),
                      $.attr('src', G.to_150(data.product_thumb_url)),
                    );
                  },
                );
              }),
            ),

            $.on('click', '.bp_color .btn_bp_color_thumb', function (e) {
              $.find1($.closest(e.$currentTarget, '.bp_color_thumb'), '.input .bp_color_thumb_file').click();
            }),

            $.on('click', '.bp_color .btn_bp_color_thumb_delete', function (e) {
              if (!confirm('색상 대표 썸네일을 삭제하시겠습니까?')) return;

              const bpc = $.closest(e.$currentTarget, '.bp_color');
              _p.go(
                $.delete('/@api/base_product_color_thumb/delete', {
                  base_product_color_id: $.attr(bpc, 'bpc_id'),
                }),
                function () {
                  box.set(
                    'bp->_->base_product_colors->(#' + $.attr(bpc, 'bpc_id') + ')->product_thumb_url',
                    null,
                  );
                  box.set(
                    'bp->_->base_product_colors->(#' + $.attr(bpc, 'bpc_id') + ')->product_thumb_id',
                    null,
                  );
                  $.attr($.find1(bpc, '.thumb img'), 'src', '');
                },
              );
            }),

            $.on(
              'change',
              '.bp_color input[type="file"].bp_color_seamless_texture_url_file',
              ___(_p.v('$currentTarget'), async function (input) {
                $.don_loader_start();
                const bpc = $.closest(input, '.bp_color');
                const data = await $.upload(input);

                const seam_img_url = data.url;
                const url = await go(
                  makeImage(seam_img_url),
                  (img) =>
                    axios.post('/@fileUpload/composite/convert_seam_image_to_seamless_url', {
                      seam_img_url,
                      width: img.width,
                      height: img.height,
                    }),
                  sel('data'),
                );

                box.set(
                  'bp->_->base_product_colors->(#' + $.attr(bpc, 'bpc_id') + ')->seamless_texture_url',
                  url,
                );
                await $.post(
                  '/@api/base_product_color/update',
                  _p.omit(box.sel('bp->_->base_product_colors->(#' + $.attr(bpc, 'bpc_id') + ')'), ['_']),
                );
                _p.go(
                  bpc,
                  $.find('.bp_color_seamless_texture_url .seamless_texture_url img'),
                  $.attr('src', G.to_150(url)),
                );
                $.don_loader_end();
              }),
            ),

            $.on('click', '.bp_color .btn_bp_color_seamless_texture_url', function (e) {
              $.find1(
                $.closest(e.$currentTarget, '.bp_color_seamless_texture_url'),
                '.input .bp_color_seamless_texture_url_file',
              ).click();
            }),

            $.on('click', '.bp_color .btn_bp_color_seamless_texture_url_delete', async function (e) {
              if (!confirm('색상 텍스처를 삭제하시겠습니까?')) return;

              const bpc = $.closest(e.$currentTarget, '.bp_color');

              box.set(
                'bp->_->base_product_colors->(#' + $.attr(bpc, 'bpc_id') + ')->seamless_texture_url',
                null,
              );
              await $.post(
                '/@api/base_product_color/update',
                _p.omit(box.sel('bp->_->base_product_colors->(#' + $.attr(bpc, 'bpc_id') + ')'), ['_']),
              );
              $.attr($.find1(bpc, '.seamless_texture_url img'), 'src', '');
            }),
            $.on(
              'change',
              '.bp_color_face input.img_upload[type="file"]',
              ___(_p.v('$currentTarget'), function (input) {
                const $bpcf = $.closest(input, '.bp_color_face');
                const $file_area = $.closest(input, '.file_area');

                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/base_product_color_face/update/' + _p.go($bpcf, $.attr('bpcf_id')),
                    data: {
                      base_product_id: box.sel('bp').id,
                      key: $.attr($file_area, 'key'),
                    },
                  }),
                  function (data) {
                    _p.go(
                      $file_area,
                      $.find('img'),
                      $.attr('src', G.to_150(data[$.attr($file_area, 'key')])),
                    );
                  },
                );
              }),
            ),

            $.on(
              'change',
              '.bp_color_face input.video_file[type="file"]',
              ___(_p.v('$currentTarget'), function (input) {
                const $bpcf = $.closest(input, '.bp_color_face');
                const $file_area = $.closest(input, '.file_area');

                return _p.go(
                  $.upload(input, {
                    url: '/@fileUpload/base_product_color_face/update/' + _p.go($bpcf, $.attr('bpcf_id')),
                    data: {
                      base_product_id: box.sel('bp').id,
                      key: $.attr($file_area, 'key'),
                    },
                  }),
                  function (data) {
                    _p.go($file_area, $.find('video'), $.attr('src', data[$.attr($file_area, 'key')]));
                  },
                );
              }),
            ),
            $.on('click', '.base_product_color_face_price_info .bpcf_price_info_save', async function (e) {
              const form = go(
                e.currentTarget,
                $closest('.base_product_color_face_price_info'),
                $findAll('input'),
                map((input_el) => [input_el.name, Number(input_el.value)]),
                object,
              );
              const id = go(e.currentTarget, $closest('.bp_color_face'), $attr('bpcf_id'));
              $.don_loader_start();
              await $.post('/@api/base_product_color_faces', { ...form, id });
              $.don_loader_end();
            }),
          );

          //
          _p.go(
            $('#bp_editor .bp_cate_list_filter'),
            $.on('change', 'select.cate_list_selector', function (e) {
              $.html(
                $1('select.cate_item_selector'),
                pug`
            option.none[value=""] 선택하세요.
            ${_p.go(
              _p.sum(
                box.sel(
                  `cate_lists->(#${$.val($.find1($1(e.currentTarget), 'option:checked'))})->_->cate_items`,
                ),
                (cate_item) => pug`
            option[value="${cate_item.id}"] ${cate_item.name}
            `,
              ),
              _p
                .if2(_p.negate(_p.val('length')))(
                  _p.a(pug`
            option.none[value="" selected] 상품 카테고리를 먼저 선택하세요.`),
                )
                .else(_p.idtt),
            )}
            `,
              );
              $.post('/@api/base_product/update', {
                id: box.sel('bp').id,
                cate_list_id: $.val($.find1($1(e.currentTarget), 'option:checked')) || null,
                cate_item_id: null,
              });
              _p.go(e.currentTarget, filterBlur);
              $.attr($1('ul.custom_filter_list'), 'is_hide', true);
            }),
            $.on('change', 'select.cate_item_selector', function (e) {
              $.post('/@api/base_product/update', {
                id: box.sel('bp').id,
                cate_item_id: $.val($.find1($1(e.currentTarget), 'option:checked')) || null,
              });
              $.attr(
                $1('ul.custom_filter_list'),
                'is_hide',
                !$.val($.find1($1(e.currentTarget), 'option:checked')),
              );
              _p.go(e.currentTarget, filterBlur);
            }),
            $.on('change', 'select.brand_selector', function (e) {
              $.post('/@api/base_product/update', {
                id: box.sel('bp').id,
                brand_filter_id: $.val($.find1($1(e.currentTarget), 'option:checked')) || null,
              });
              _p.go(e.currentTarget, filterBlur);
            }),
            $.on('change', 'li.custom_filter_item input', function (e) {
              if ($.hasClass(e.currentTarget, 'ing'))
                return (e.currentTarget.checked = !e.currentTarget.checked);
              _p.go(
                $.addClass(e.currentTarget, 'ing'),
                function () {
                  return $.post(
                    '/@api/bp_filter_list',
                    _p.extend(
                      {
                        bp_id: _p.val(box.sel('bp'), 'id'),
                        is_checked: e.currentTarget.checked,
                      },
                      box.sel($.closest(e.currentTarget, '.custom_filter_item')),
                    ),
                  );
                },
                function (r) {
                  if (!r)
                    return alert('삭제에 문제가있습니다. 다시 시도해보시고 안되면 개발팀에 문의주세요.');
                  _p.go(e.currentTarget, $.removeClass('ing'), $.closest('.bp_tag_filter'), filterBlur);
                },
              );
            }),
            $.on('change', 'li.bp_tag_filter input', function (e) {
              if ($.hasClass(e.currentTarget, 'ing'))
                return (e.currentTarget.checked = !e.currentTarget.checked);
              _p.go(
                $.addClass(e.currentTarget, 'ing'),
                function () {
                  return (e.currentTarget.checked ? $.post : $.delete)(
                    '/@api/type_category',
                    _p.extend({
                      attached_id: _p.val(box.sel('bp'), 'id'),
                      attached_type: 'bp_tag_filters',
                      category_id: window.box.sel($.closest(e.currentTarget, 'li.bp_tag_filter')).id,
                    }),
                  );
                },
                function () {
                  _p.go(e.currentTarget, $.removeClass('ing'), $.closest('.bp_tag_filter'), filterBlur);
                },
              );
            }),
            $.on('click', '.add_custom_badge', async (e) => {
              const bp_id = _p.val(box.sel('bp'), 'id');

              await MuiF.openFrame(DfOMPCustomBadgeRelationBPMuiF.frame, async (f, p, [t]) => {
                f.closed = (f, v) => {
                  if (v) {
                    window.location.reload();
                  }
                };

                const bp_current_badges = box.sel('custom_badges');

                // 커스텀뱃지 먼저 조회
                const prev = await t.makeData();
                const custom_badges = go(
                  prev,
                  sel('custom_badges'),
                  map((p) => {
                    return {
                      ...p,
                      has_badge: some((badge) => badge.custom_badge_id == p.id, bp_current_badges),
                    };
                  }),
                );

                t.makeData = async () => {
                  return { bp_ids: bp_id, custom_badges };
                };
              });
            }),
            $.on('click', '.go_to_cate_list', () => {
              location.href = `/cate_list`;
            }),
          );

          // setTimeout(function() {
          //   var galleryTop = new Swiper('.swiper_thumbnails', {
          //     spaceBetween: 30,
          //     slidesPerView: 'auto',
          //     centeredSlides: true,
          //     grabCursor: true,
          //     loop: true,
          //     navigation: {
          //       nextEl: '.swiper-button-next',
          //       prevEl: '.swiper-button-prev'
          //     },
          //     pagination: {
          //       el: '.swiper-pagination',
          //       clickable: true,
          //     }
          //   });
          //   var galleryThumbs = new Swiper('.swiper_thumbnails_icon', {
          //     spaceBetween: 30,
          //     centeredSlides: true,
          //     slidesPerView: 'auto',
          //     loop: true,
          //     touchRatio: 0.2,
          //     slideToClickedSlide: true
          //   });
          //   galleryTop.controller.control = galleryThumbs;
          //   galleryThumbs.controller.control = galleryTop;
          // }, 2000);
        },
      },
    ],
  });

  function reset_bpfs_bpcs(don_frame, bp) {
    box.set('bp->_->base_product_faces', bp._.base_product_faces);
    box.set('bp->_->base_product_colors', bp._.base_product_colors);

    $.html(
      $.find1(don_frame, '.bp_faces_editor .bp_faces'),
      G.$teach_$bp_face(box.sel('bp->_->base_product_faces'), box.sel('is_user')),
    );

    $.html(
      $.find1(don_frame, '.bp_colors_editor .bp_colors'),
      _p.map(
        box.sel('bp->_->base_product_colors'),
        _p(G.df_$bp_color_item, _p, box.sel('color_filter_lists'), box.sel('is_user')),
      ),
    );
  }
}
